import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import HeaderStyles from './Header.module.css'

export default class Header extends React.Component {
  render() {
    let url = window.location.href.split('/');
    let a1 = url[url.length - 1]
    if (a1 == 'a1' || a1 == 'a1zoom' || a1 == 'industry') {
      return null;
    }
    return (
      <div className={HeaderStyles.navbar}>
          <Navbar collapseOnSelect expand="lg">
          <span className={HeaderStyles.container}>
            <Navbar.Brand href="/">
              <span className={HeaderStyles.brand}>
                  <img className={HeaderStyles.brand_logo}
                      alt="logo"
                      src="/assets/images/logo.svg"/>
                  <span className={HeaderStyles.brand_title}>
                    Tammy
                  </span>
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                <Nav.Link href="/">Work</Nav.Link>
                <Nav.Link href="/about">About Me</Nav.Link>
                <Nav.Link href="/assets/tam_resume.pdf" target="_blank">Resume</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </span>
            </Navbar>
      </div>
    );
  }
}