import React, { Component } from 'react';
import classnames from 'classnames';
import A1Styles from './A1.module.css';
import Projects from './Projects';
import ProjectInfo from './ProjectInfo';
import ProjectStyles from './Projects.module.css';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import ProjectsStyles from './Projects.module.css';

import { Controller, Scene,  } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

export default class A1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
  }
  render() { 
    
    return (

      <div className={A1Styles.body}>
      <img src="/assets/images/a1/bg.png" width="100%" className={A1Styles.bg} />
      <div className={classnames(UtilitiesStyles.d_flex, A1Styles.top)}>
        <div className={UtilitiesStyles.flex_1}>
          <h1>Save the sea turtles</h1>
            <p className={A1Styles.follow}>Follow Sally the sea turtle as she travels through a polluted ocean!</p>

          <div id="trigger" />

          <div id="trigger2" />


        </div>
      </div>
      <img src="/assets/images/a1/coral.png" className={A1Styles.coral}/>


          <Controller>

 
            <Scene
            triggerElement="#trigger"
            triggerHook="onCenter"
              duration={7000}>
              {(progress) => {
                return (

                  <Timeline totalProgress={progress} paused>
                      <Timeline
                        target={
                        <img src="/assets/images/a1/sally.png" width="300px" className={A1Styles.sally} style={{top: '200px', right: '190px'}}/>
                        }
                      >
                        <Tween
                          from={{
                            css: {
                              right: '10%',
                              rotation: 0,
                              width: '500px',
                              top: '14%'
                            },
                          }}
                          to={{
                            css: {
                              right: '70%',
                              rotation: -60,
                              width: '200px',
                              top: 2500,
                            },
                          }}
                        />
                        <Tween
                        from={{
                          css: {
                            right: '70%',
                            rotation: -60,
                            width: '200px',
                            top: 2500,
                          },
                        }}
                        to={{
                          css: {
                            right: '0%',
                            rotation: -60,
                            width: '200px',
                            top: 5000,
                          },
                        }}
                        />
                        <Tween

                        from={{
                          css: {
                            right: '0%',
                            rotation: -60,
                            width: '200px',
                            top: 5000,
                          },
                        }}
                        to={{
                          css: {
                            right: '50%',
                            rotation: -60,
                            width: '200px',
                            top: 7200,
                          },
                        }}
                        />
                      </Timeline>
                  </Timeline>
              )}}
              </Scene>

          <Scene
          triggerElement="#trigger2"
            duration={1000}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-300px',
                    top: '1300px'
                  }}
                  staggerTo={{
                    right: '400px',
                    top: 1400,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.stagger}>
                    <img src="/assets/images/a1/cig.png" style={{marginBottom: '40px'}}/>
                    <span className={classnames(A1Styles.stagger1)}>580 Billion</span>
                    <div className={classnames(A1Styles.stagger2)}>Cigarette butts are thrown into the ocean every year by the European union alone! Sally sometimes eats these by accident</div>

                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
          triggerElement="#trigger2"
            duration={3000}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    left: '-300px',
                    top: '3900px'
                  }}
                  staggerTo={{
                    left: '400px',
                    top: 3500,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.stagger}>
                    <img src="/assets/images/a1/cups.png" style={{ marginBottom: '40px' }}/>
                    <span className={classnames(A1Styles.stagger1)}>16 Billion</span>
                    <div className={classnames(A1Styles.stagger2)}>Coffee cups butts are thrown into the ocean every year by the European union. Some of Sally's hermit friends use these cups as shells but they provide no safety.</div>

                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
          triggerElement="#trigger2"
            duration={4000}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-30%',
                    top: '4100px'
                  }}
                  staggerTo={{
                    right: '100%',
                    top: 4100,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.hermit}>
                    <img src="/assets/images/a1/hermit.png" style={{ marginBottom: '40px' }}/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={2000}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-30%',
                    top: '2300px'
                  }}
                  staggerTo={{
                    right: '70%',
                    top: 2900,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.life}> 
                    <h1>Life is hard for sally</h1>
                    <span>She has to travel through garbage!<br/> 
                    It is hard for her to avoid sometimes. It makes her stop swimming for a bit as she tries to decide what to do.</span>

                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={4700} >
            {(progress) => (
              <div width="400px" style={{zIndex: 9999999999999}}> 
                <Tween
                  staggerFrom={{
                    left: '-300px',
                    top: '4700px',
                    zIndex: 9999999999
                  }}
                  staggerTo={{
                    left: '300px',
                    top: 5500,
                    ease: 'Strong.easeIn',
                    zIndex: 9999999999

                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.life_gross}> 
                    <h1>Oh no! Here comes the sewage</h1>
                    <span>Sally can't survive it. You can barely see her! Where is she? The sewage is deadly for her!</span>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={2700}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    left: '-300px',
                    top: '2800px'
                  }}
                  staggerTo={{
                    left: '1200px',
                    top: 3300,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.baby}> 
                    <img src="/assets/images/a1/rings.png" width="200px"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={3000}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    left: '-300px',
                    top: '3200px'
                  }}
                  staggerTo={{
                    left: '1200px',
                    top: 3200,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.baby}> 
                    <img src="/assets/images/a1/cup.png" width="200px"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={2700}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-300px',
                    top: '2800px'
                  }}
                  staggerTo={{
                    right: '1200px',
                    top: 3300,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.baby}> 
                    <img src="/assets/images/a1/btlpng.png" width="200px"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={3300}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-100px',
                    top: '3800px'
                  }}
                  staggerTo={{
                    right: '1200px',
                    top: 2300,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.baby}> 
                    <img src="/assets/images/a1/bag.png" width="200px"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={6500}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    left: '-3000px',
                    top: '6800px'
                  }}
                  staggerTo={{
                    left: '100px',
                    top: 7200,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.save}> 
                    <h1>Want to stop the cycle?</h1>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger2"
            duration={6500}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    right: '-3000px',
                    top: '6800px'
                  }}
                  staggerTo={{
                    right: '100px',
                    top: 7150,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.baby_save}> 
                    <h1>Sally ends up beached and dead and in the distance, baby sea turtles head towards the sea. It's a never ending cycle of death.</h1>
                    <h1 style={{fontWeight: '900'}}>Save the ocean</h1>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            duration={1000} pin enabled>
            {(progress) => (
              <div width="100%" style={{ fontSize: 300, position: 'absolute', zIndex: 9999999999999999, top: 4300, right: 0 }}>
                <Tween
                  staggerFrom={{
                    right: '-100px',
                    top: '0px'
                  }}
                  staggerTo={{
                    right: '100px',
                    top: 250,
                    ease: 'Strong.easeIn',
                  }}
                  totalProgress={progress}
                  paused
                >

                  <div style={{zIndex: 9999999999999999 }}><img width="200px" src="/assets/images/a1/drain.png"/></div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            duration={1000} pin enabled>
            {(progress) => (
              <div width="100%" style={{ fontSize: 300, position: 'absolute', zIndex: 99999, top: 4300, right: 0 }}>
                <Tween
                  staggerFrom={{
                    right: '-100%',
                    top: '0px'
                  }}
                  staggerTo={{
                    right: '90%',
                    top: 250,
                    transform: 'scale(12) translate(-40px)',
                    ease: 'Strong.easeIn',
                  }}
                  totalProgress={progress}
                  paused
                >

                  <div style={{zIndex: 99999999 }}><img width="200px" src="/assets/images/a1/gross.png"/></div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger3"
            duration={800}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    top: '7500px'
                  }}
                  staggerTo={{
                    top: 2000,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >
                  <div className={A1Styles.baby}>
                  <img src="/assets/images/a1/babysea.png" width="100%"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          <Scene
            triggerElement="#trigger3"
            duration={340}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    top: '7100px',
                    right: '-300px'
                  }}
                  staggerTo={{
                    top: 7100,
                    ease: 'Strong.easeIn',
                    right: '50%'
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >
                  <div className={A1Styles.baby}>
                  <img src="/assets/images/a1/grave.png" width="150px"/>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>

          <Scene
          triggerElement="#trigger2"
            duration={500}>
            {(progress) => (
              <div width="400px">
                <Tween
                  staggerFrom={{
                    left: '0px',
                    top: '1000px'
                  }}
                  staggerTo={{
                    left: '2500px',
                    top: 700,
                    ease: 'Strong.easeIn',
                  }}
                  stagger={0.15}
                  totalProgress={progress}
                  paused
                >

                  <div className={A1Styles.fish}>
                    <img src="/assets/images/a1/fish.png" />

                  </div>
                </Tween>
              </div>
            )}
          </Scene>
          
              </Controller>
              <img src='/assets/images/a1/beach.png' className={A1Styles.beach}/>
              <div className={A1Styles.end} id="trigger3"></div>


        
  </div>




    );
  }
}

