import React, { Component } from 'react';
import A3Styles from './A3.module.css';
import ProjectStyles from './Projects.module.css';
import Projects from './Projects';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import ImageZoom from 'react-medium-image-zoom';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export default class A3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
  }

  render() {
    return (
      <Projects
          img="/assets/images/a3/followlogo.svg"
          classNameImg={A3Styles.sally_img}
          title="FollowU"
          size="30%"> 
        <div className={A3Styles.container}>
            <div id="overview" style={{marginTop: 50}}>
              <div style={{margin: '20px auto'}}>
                <h1>Design Prompt</h1>
                  <p>Realize a retail environment using an augmented reality prototyping tool. Using the prototyping tool, use proxemic interactions to explore an interaction design theme. The goal is to design a retail environment that force us to confront and understand challenges presented by the interaction design themes. (Affordances, Dark Patterns & Privacy)</p>
              </div>
              <div style={{margin: '20px auto'}}>
                <h1>Tools</h1>
                <p>Pencil + paper, Photoshop, Adobe After Effects, iMovie, Wiarframe</p>
              </div>
              <hr/>
            <h1>The Solution</h1>
            <p>For this project, I decided to tackle both deception (dark patterns) and privacy as design themes.</p>
            <div className={A3Styles.video_container}>
              <iframe src="https://www.youtube.com/embed/PfnWt8tdpgs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={A3Styles.video}></iframe>
            </div>
            <div>
              <h1 style={{marginTop: 50}}>How it works</h1>
                <p>As the user enters a retail environment, a line follows them around to show everywhere they have been. As the system learns more about them, they are provided with arrow with items they might need. The only way to make an item go away is to follow it to where it points.</p>
              <h1 style={{ marginTop: 50 }}>Rationale</h1>
              <p>FollowU take advantage of proxemic interaction with both reactive and proactive interactions. The system is reactive as the line follows the user and displays where it has been but also proactive in sourcing items the user might need. It is reactive again in re-calibrating arrow directions when the user chooses a direction to follow.</p>

              <p>For the design interaction themes, I chose to do both deception and privacy by using the violation of privacy as a means to do something they might not do other wise. This system is a violation of privacy in that it clearly indicates where a person has been. I considered how shopping bags in real life do something similar but conceal the actual contents but provide a hint at where people have been. It is this violation of privacy that drives the deception in this system. It will be helpful 9 out of 10 times by pointing to items that are actually useful but will use what it learns about people to point to personal and embarrassing items. In order to get rid of it, the user must follow the arrow to the item. It intentionally forces the user to go where they may not want to</p>
            </div>
          </div>
        <hr/>
        <h1>Process</h1>


          <div className={ProjectStyles.title_flex} id="ideation">

            <h1>Ideation</h1>
            <p>I started this assignment by considering my own experiences in retail and sketches potential ideas. I wanted to take advantage of actions users were already doing while they shopped</p>


      <ImageZoom
            image={{
              src: '/assets/images/a3/1.jpeg',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/1.jpeg',
            }}
          />
      </div>


      <div className={ProjectStyles.title_flex} id="feedback">
          <h1>Design Critique: Iteration 1</h1>
      
            <p>I presented my ideas to my peers so game me suggestions about how to improve my designs. The idea is liked the most was one where the system tracked where the user had been and mark it on the ground. Then it would mark arrows on the ground where they might want to go. I received feedback to maybe use the arrows as a way to force people to go into a direction. I really liked this idea and decided to explore it further.</p>
      
      </div>
      <div className={ProjectStyles.title_flex} id="ideation2">
      <h1>Ideation: Part 2</h1>

      
            <p>I created 10 more sketches following the idea mentioned above. I considered how retail experiences online differ from those offline and how they could be used to my advantage. This time, I considered how to embarrass the user even more. The system had to know the user well enough to ensure that whatever the embarrassment was, it would be enough to force them into action. Once I had an idea about what I wanted to do, I created an interactive prototype in Wiarframe.</p>
      
      <ImageZoom
            image={{
              src: '/assets/images/a3/2.jpeg',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a3/2.jpeg',
            }}
          />
      </div>

      <hr/>

      <div className={ProjectStyles.title_flex} id="final">
      <h1>Final Design</h1>
            <div className={A3Styles.video_container}>
              <iframe src="https://www.youtube.com/embed/PfnWt8tdpgs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={A3Styles.video}></iframe>
            </div>
          </div>

      </div>
      

      <div className={ProjectStyles.title_flex} id="nxtstep">
      <h1>Reflection</h1>
      <p>I had a lot of fun being evil for this assignment. I faced the most difficulty in trying to make Wiarframe work because it would crash from time to time.</p>
      <p>The FollowU system considers privacy in the real world. It attempts to force users into action by embarrassing or annoying them. In the physical world, personal items are hidden in shopping bags, although there is some leakage of privacy because the bag reveals where the individual has been.</p>
      <p>I also wanted to use partial re-enforcement to try and make the system something they want to use despite being annoyed or embarrassed by it. FollowU shows an extreme case by providing valuable recommendations 95% of the time will show embarrassing or annoying recommendations 5% of the time for ad revenue, but the real world may provide less embarrassment and more annoyance or discomfort. This is evident in places like news sites that require you to turn ad-blocker off, or accepting that Google collects user data in order to use goods and services, or having to watch a 5 second ad in order to see your favourite YouTuber. The value of a product determines how much someone is willing to put up with annoyances and embarrassment. This begs 2 main questions: how much is someone willing to put up with annoyances to receive benefits? and are people willing yp sacrifice privacy (like in the case of Google) in order to access goods and services?</p>
      <p>The answer, I believe, is yes. People are complacent to the idea that personal data is taken and used against them. As a society, it is important to consider how valuable these tools are if they take away our privacy and use our data for private capital.</p>
      </div>
        <Nav items={['overview', 'ideation', 'feedback', 'ideation2', 'final', 'nxtstep'] }>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#ideation">Ideation</a></li>
          <li><a href="#feedback">Design Critique</a></li>
          <li><a href="#ideation2">Ideation: Part 2</a></li>
          <li><a href="#final">Final Design</a></li>
          <li><a href="#nxtstep">Reflection</a></li>
      </Nav>



    </Projects>





    );
  }
}

