import React, { Component } from 'react';
import A0Styles from './A0.module.css';
import ProjectStyles from './Projects.module.css';
import Projects from './Projects';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import ImageZoom from 'react-medium-image-zoom';

export default class A0 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
  }


  render() {
    return (

      <Projects
      img="/assets/images/meapppromo2.png"
      title="TravelW/Me"
      size="30%"> 

      <div className={A0Styles.container}>
      <div id="overview" style={{marginTop: 50}}>

      <div style={{margin: '20px auto'}}>
        <h1>Design Prompt</h1>
        <p>Sketch, design, implement via Balsamiq and document via a visual portfolio an application where the central interaction represents some aspect of your personality or who you are.</p>
      </div>

      <div style={{margin: '20px auto'}}>
          <h1>Tools</h1>
        <p>Pencil + paper, Balsamiq</p>
      </div>

            <h1>Final Design</h1>
            <p>The final design is as follows! </p>

            <div className={UtilitiesStyles.text_center}>
              <video width="400px" height="auto" autoPlay loop>
                <source src="/trvid.mp4" type="video/mp4" />
              </video>
              </div>

      <hr/>
      </div>
      <div className={ProjectStyles.title_flex} id="ideation">
          <h1>Ideation</h1>
      
      <p>I started this project by jotting down my personality traits and things I enjoy. When I do projects like this one, it seems like I forget everything I know about myself. Coming up with 10 ideas seemed difficult! Regardless, I came up with these ideas. They represent my love for design, traveling, problem solving, curiosity, programming, reading and painting.</p>

      <ImageZoom
            image={{
              src: '/assets/images/Scan.jpeg',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/Scan.jpeg',
            }}
          />
      </div>

      <div className={ProjectStyles.title_flex} id="feedback">
          <h1>Design Critique</h1>
      
      <p>I got a chance to present my sketches and receive feedback. The comments made me consider the interactability of my app. There had to be a way for users to know how to use it. Also, since it was a mobile app, I had to consider the different gestures afforded to me. I ended up picking an idea about traveling where sliding the plane along would show images of places I had traveled. </p>
      
      </div>
      <div className={ProjectStyles.title_flex} id="ideation2">
      <h1>Ideation: Part 2</h1>

      
      <p>With my travel idea chosen, it was time to create another 10 ideas. This time, I wanted to try and incorporate the best of my own photography. I considered ideas with maps, without maps, 2D and 3D globes, timelines and even a passport. In the end, I decided to pick a timeline that showcased my favourite picture of the city. (The middle one on the right hand page if you were curious)</p>
      
      <ImageZoom
            image={{
              src: '/assets/images/Scan2.jpg',
              className: 'img',
              style: { width: '100%', marginTop: 50, filter: 'grayscale(1)' }
            }}
            zoomImage={{
              src: '/assets/images/Scan2.jpg',
              style: { filter: 'grayscale(1)' }
            }}
          />
      </div>

      <div className={ProjectStyles.title_flex} id="proto">
      <h1>Prototyping</h1>
      <p>I started creating the application in Balsamiq. It was difficult in that Balsamiq was limited and I had to get creative with the way I was creating the interactions. I was asked to consider what every design choice meant to me. Why place images in portrait mode? Do the dates have significance? Why cut off the timeline and not show the whole thing? </p>
      <p>I was able to present my ideas to the class and get further feedback. An interesting point a classmate brought up was to include country names as well as city names.</p>
      <ImageZoom
            image={{
              src: '/assets/images/trme.jpg',
              className: 'img',
              style: { width: '30%', marginTop: 50, }
            }}
            zoomImage={{
              src: '/assets/images/trme.jpg',
            }}
          />

      <ImageZoom
            image={{
              src: '/assets/images/meapppromo.png',
              className: 'img',
              style: { width: '70%', marginTop: 50, }
            }}
            zoomImage={{
              src: '/assets/images/meapppromo.png',
            }}
          />
      </div>

      <div className={ProjectStyles.title_flex} id="final">
      <h1>Final Design</h1>
      <p>The following design is the final application called TravelW/Me. In the end, I decided to have the application be in portrait mode because it is the orientation people use the most. I also decided to have the timeline cut off so the next destination is just as much of a surprise to the user as it was to me! Finally, I decided to add dates to signify how travel became a part of my life recently</p>
      
      <div className={UtilitiesStyles.text_center}>
      <video width="400px" height="auto" autoPlay loop>
              <source src="/trvid.mp4" type="video/mp4"/>
            </video>
      <div className={UtilitiesStyles.text_center}>
      </div>

      </div>
      </div>



      <div className={ProjectStyles.title_flex} id="intr">
      <h1>Try it out</h1>
      <p>You can play around with it yourself with the prototype below or you can <a href="https://balsamiq-wireframes.appspot.com/view/8AxMuzyVF6FkCGLVaUsNPW?f=N4IgUiBcAMA0IDkpxAYWfAMhkAhHAsjgFo4DSUA2gLoC+QA=" target="_blank" rel="noopener noreferrer"> check it out on balsamiq</a>.

      </p>
      <div className={UtilitiesStyles.text_center}>

      <iframe title="Balsamiq App" className={A0Styles.prototype_new} scrolling="no" importance="high" src="/assets/TravelWme.pdf#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&zoom=1&view=FitH,top"/>


      <div style={{margin: '20px auto', textAlign: 'center'}}>
      <a href="https://balsamiq-wireframes.appspot.com/view/8AxMuzyVF6FkCGLVaUsNPW?f=N4IgUiBcAMA0IDkpxAYWfAMhkAhHAsjgFo4DSUA2gLoC+QA=" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button} style={{margin: '20px auto'}}>
          Check App on Balsamiq</a>
      </div>

      </div>


      </div>

      



      <div className={ProjectStyles.title_flex} id="nxtstep">
      <h1>Next Steps</h1>
      <p>Right now, the app centers around showing one or two of my favourite pictures. In the next iteration, I'd like to see if I can add more pictures and figure out how to appropriately differentiate different types of scrolling. One for next city and one for next picture.</p>
      </div>
      <Nav items={['overview', 'ideation', 'feedback', 'ideation2', 'proto', 'final', 'intr', 'nxtstep'] }>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#ideation">Ideation</a></li>
          <li><a href="#feedback">Design Critique</a></li>
          <li><a href="#ideation2">Ideation: Part 2</a></li>
          <li><a href="#proto">Prototyping</a></li>
          <li><a href="#Final">Final Design</a></li>
          <li><a href="#intr">Try it out!</a></li>
          <li><a href="#nxtstep">Next Steps</a></li>
      </Nav>

      </div>



    </Projects>





    );
  }
}

