import React from 'react';
import ProjectThumbnailStyles from './ProjectThumbnail.module.css';

export default class ProjectThumbnail extends React.Component {
  render() {
    let img = "url(" + this.props.image + ")";
    return (
      <a href={this.props.location} className={ProjectThumbnailStyles.project} ref={this.myrefs} style={{backgroundImage: img, backgroundSize: this.props.size ? this.props.size : '50%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
        <div className={ProjectThumbnailStyles.project_hover}>
          <div>
            <h3>{this.props.title}</h3>
          <p>{this.props.roles}</p>
          </div>
        </div>
      </a>
    );
  }
}

