import React, { Component } from 'react';
import AboutStyles from './About.module.css';
import Gallery from "react-photo-gallery";
import UtilitiesStyles from './utilities.module.css';
import ImageZoom from "react-medium-image-zoom";

export const photos = [
  {
    src: '/assets/images/paint4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint1.png',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/paint3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/paint5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/3.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/20.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/2.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/29.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/30.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/4.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/24.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/5.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/6.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/7.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/8.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/9.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/21.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/10.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/11.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/12.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/13.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/14.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/15.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/16.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/17.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/18.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/19.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/22.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/23.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/25.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/26.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/27.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/28.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/31.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/33.jpg',
    width: 3,
    height: 4
  },
  {
    src: '/assets/images/photos/32.jpg',
    width: 4,
    height: 3
  },

  {
    src: '/assets/images/photos/34.jpg',
    width: 4,
    height: 3
  },
  {
    src: '/assets/images/photos/35.jpg',
    width: 4,
    height: 3
  },
];


class About extends Component {
  render() {
    return (
      <div>
      <div className={AboutStyles.container}>
        <div className={AboutStyles.about_container}>
          <div className={AboutStyles.flex_item}>
            <img className={AboutStyles.picture} src="/assets/images/me.png" alt="Me"/>
          </div>
          <div className={AboutStyles.flex_item}>
              <h5>Hello, I'm Taamannae <span role="img" aria-label="waving hand">👋</span></h5>
              <p style={{marginTop: "-25px"}}>/ta•man•ah/</p>

              <h5>I am passionate about integrating <b>mindfulness</b>, <b>empathy</b> & <b>neuroscience</b> in crafting accessible interactions & experiences</h5>
              <p> I am currently a user experience designer for <a href="https://momentcrm.com/" target="_blank" rel="noopener noreferrer">@Moment</a> and I've been a software engineer intern <a href="http://ibm.com/" target="_blank" rel="noopener noreferrer">@IBM</a>. I am also pursuing a <a href="https://ischool.utoronto.ca/areas-of-study/bachelor-of-information/" target="_blank" rel="noopener noreferrer">Bachelor of Information</a> at the University of Toronto. I love working on puzzles and projects that keep me on my toes in order to challenge myself and grow as a designer & developer.
            </p>
              <div style={{marginBottom: '10px'}}>
                <li>I have 7 a's in my full name</li>
                <li>I've been designing since I was 10, when I taught myself HTML/CSS and Photoshop</li>
                <li>I've been to 9 countries</li>
                <li>I can knit & sew</li>
              </div>
              <p>When I'm not trying to solve puzzles, I like to ✈️travel, 📚read, 🎨paint, ✏️illustrate and take 📷photographs of the world around me. </p>
            <p>Curious about why I started designing at 10 years old or what I've been up to? Feel free to email me!</p>
              <a href="mailto:taamannae@gmail.com" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
                Email me!</a>
          </div>
        </div>


      </div>
        <Gallery photos={photos} direction={"column"}/>

      </div>
    );
  }
}

export default About;
