import React from 'react';
import FooterStyles from './Footer.module.css'

export default class Footer extends React.Component {
  render() {

    let url = window.location.href.split('/');
    let a1 = url[url.length -1]
    if (a1 == 'a1' || a1 == 'a1zoom' || a1.includes('industry')) {
      return null;
    }
    return (
      <div className={FooterStyles.footer}>
        <div className={FooterStyles.container}>

        <a href="mailto:taamannae@gmail.com">
          <i className="fa fa-2x fa-envelope"></i></a>
        <a href="https://dribbble.com/taamannae" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-2x fa-dribbble"></i>
        </a>
        <a href="https://www.linkedin.com/in/taamannae/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-linkedin-in"></i>
        </a>
        <a href="https://github.com/Taamannae" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-github-alt"></i>
        </a>
        <a href="https://medium.com/@taamannae" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-2x fa-medium-m"></i>
        </a>
        </div>
        <p>Made with <i className="fa fa-code"></i> by me </p>
      </div>
    );
  }
}