import React, { Component } from 'react';
import classnames from 'classnames';
import ProjectInfoStyles from './ProjectInfo.module.css';
import Utilitiestyles from '../utilities.module.css';
import _ from 'lodash';

class ProjectInfo extends Component {
  render() {
    let extras = null;
    if (!this.props.noextra) {
      let roles = []
      _.forEach(this.props.roleList, function(value) {
        roles.push(<span>{value}</span>)
      });
      let tools = []
      _.forEach(this.props.tools, function(value) {
        tools.push(<span>{value}</span>)
      });

      extras = (
        <div className={
          classnames(Utilitiestyles.d_flex, ProjectInfoStyles.section, Utilitiestyles.justify_center, ProjectInfoStyles.quick_info)}>
          <div>
            <h5>My Role</h5>
            {roles}
          </div>
          <div>
            <h5>Timeline</h5>
            {this.props.timeline}
          </div>
          <div>
            <h5>Tools</h5>
            {tools}

          </div>
        </div>

      );
    }
    return (
      <div className={ProjectInfoStyles.tools}>
        <h3>{this.props.roles}</h3>
        <h1>{this.props.title}</h1>
        {this.props.description}
        {extras}
      </div>
    );
  }
}

export default ProjectInfo;
