import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import A0 from './projects/A0';
import A1 from './projects/A1';
import A1Project from './projects/A1Project';
import A2 from './projects/A2';
import A3 from './projects/A3';
import A1Zoom from './projects/A1Zoom';
import About from './About';
import Industry from './projects/Industry';


function AppRouter() {
  return (
    <Router>
        <Header/>
        <Route path="/" exact component={Home} />
        <Route path="/projects/a0" component={A0} />
        <Route path="/projects/a1" component={A1} />
        <Route path="/projects/a1zoom" component={A1Zoom} />
        <Route path="/projects/a1project" component={A1Project} />
        <Route path="/projects/a2" component={A2} />
        <Route path="/projects/a3" component={A3} />
        <Route path="/projects/Industry" component={Industry} />
        <Route path="/about" component={About} />
        <Footer/>

    </Router>
  );
}

export default AppRouter;
