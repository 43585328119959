import React from 'react';
import HomeStyles from './Home.module.css';
import ProjectThumbnail from './ProjectThumbnail';
import UtilitiesStyles from './utilities.module.css';


const textArray = ['a Front End Developer', 'a UX Designer', 'an Illustrator'];

const PROJECTS = [
  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         location="/projects/a3"
         title="FollowU"
         roles="Product Design"
         size="60%"
         class={HomeStyles.moment}
         image="/assets/images/a3/followlogo.svg"
     />
   )
 }, 
  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         location="/projects/a2"
         title="CoSpace"
         roles="Product Design"
         size="60%"
         class={HomeStyles.moment}
         image="/assets/images/a2/table.png"
     />
   )
 }, 
  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         location="/projects/a1project"
         title="Save the Sea Turtles"
         roles="UI Design"
         size="60%"
         class={HomeStyles.moment}
         image="/assets/images/sally.png"
     />
   )
 }, 
  {
    tags: ['ui', 'ux'],
    component: (
     <ProjectThumbnail
         location="/projects/a0"
         title="TravelW/Me"
         roles="UI Design"
         size="30%"
         class={HomeStyles.moment}
         image="/assets/images/a0main.png"
     />
   )
 }, 
]
export default class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      filters:[],
      textIdx: 0
    }
    this.bg = React.createRef()
    this.bg2 = React.createRef()
    this.bg3 = React.createRef()
  }

  filter = (filter) => {
    let state = this.state.filters;
    if (state.includes(filter)) {
      var index = state.indexOf(filter);
      state.splice(index, 1);
    } else {
      state.push(filter);
    }
    this.setState({
      filters: state
    });
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 1500);
  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }


  renderProjects = () => {
    let comp = [];
    for (let i = 0; i < PROJECTS.length; i++) {
      let project = PROJECTS[i];
      if (this.state.filters.every(v => project.tags.includes(v))) {
        comp.push(PROJECTS[i].component);
      }
    }
    if (comp.length === 0) {
      comp.push(
        <div>
          Looks like I haven't done this combination yet. But I will one day!
        </div>
      )
    }
    return comp
  }


  render() { 

    let textThatChanges = textArray[this.state.textIdx % textArray.length];

    return (
      <div className="Home">
        <div className={HomeStyles.jumbotron} onMouseMove={this._onMouseMove}>
        <div ref={this.bg} className={HomeStyles.bg}/>
        <div className={HomeStyles.info}>
         <h5 className={HomeStyles.intro_header}>Hi <span role="img" aria-label="Waving Hand Emoji">👋</span>! Welcome to my portfolio</h5>

          <div className={HomeStyles.text_bg}>
          My name is Tammy Taabassum. I am based in Toronto 
          and I am <span className={HomeStyles.text_change}>{textThatChanges}</span>.</div>
          <div className={HomeStyles.btns}>
            <a href="http://localhost:3000/assets/tam_resume.pdf" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
            Resume</a>
            <a href="mailto:taamannae@gmail.com" target="_blank" rel="noopener noreferrer" className={UtilitiesStyles.button}>
            Email Me</a>
          </div>


        </div>
        <div className={HomeStyles.bottom_shadow}></div>
        </div>
        <div className={HomeStyles.content}>
        <div id="work"/>
          <div className={HomeStyles.project_container}>
            {this.renderProjects()}
          
          </div>

        </div>
      </div>
    );
  }
}

