import React from 'react';
import NavStyles from './NavSpecial.module.css'
import Scrollspy from 'react-scrollspy'
import Fade from 'react-reveal/Fade';

export default class NavSpecial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }

    this.nav = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 348) {
      if (window.innerWidth < 774) {
        let items = this.nav.current.children[0].children[0].children;
        for (var i = 0; i < items.length; i++) {
          if (items[i].className.includes('active')) {
            let rect = items[i].getBoundingClientRect()
            let inner = window.innerWidth;

            if (rect.x > inner || rect.x < 0) {
              items[i].scrollIntoView({ block: 'nearest', inline: 'start' })
            }
            
          }
        }
      }
      this.nav.current.style.display = 'flex';
      this.setState({
        show: true
      })
    } else {
      this.nav.current.style.display = 'none';
      this.setState({
        show: false
      })
    }
      
  }
  render() {
    return (
      <div className={NavStyles.moving} ref={this.nav}>
      <Fade left when={this.state.show} duration={300}>
        <div className={NavStyles.color}>
        <Scrollspy items={this.props.items} currentClassName={NavStyles.active}>
          {this.props.children}
      </Scrollspy>
      </div>
      </Fade>
      </div>
    );
  }
}