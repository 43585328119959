
import React, { useEffect, useState } from "react";
import { json } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import * as d3 from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
} from "react-simple-maps";

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear()
    .domain([0.01, 1])
    .range(["#2f3b7e", "#ebeefc"]);

const MapChart = ({ setTooltipContent }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        json(`/country.json`).then(data => {
            setData(data);
        });
    }, []);
    return (
        <ComposableMap data-tip=""
        projection={d3.geoMercator()}

        defs={{
            viewbox:"0 -100 1000 800"
        }}
        id="mySVG"
            
        >
            {data.length > 0 && (
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map(geo => {
                            const d = data.find(s => s.countrycode === geo.properties.ISO_A3);

                            let normColor = 1;
                            if (d) {
                                
                                let avesal = ((d.zerothreecount * d.zerothreeaverage) + (d.foursevencount * d.foursevenaverage) + (d.eighttwelvecount * d.eighttwelveaverage) + (d.thirteenpluscount * d.thirteenplusaverage))/d.totalresponses
                                normColor = 0.01 + (1 - 0.01) * (92688.13048734178 - avesal) / 92688.13048734178;
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onMouseEnter={() => {
                                        setTooltipContent(geo.properties);
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipContent("");
                                    }}
                                    fill={d ? colorScale(normColor) : "#fff"}

                                    style={{
                                        default: {
                                            stroke: "#02040f",
                                            strokeWidth: 0.75,
                                            outline: "none",
                                            transition: "all 250ms"
                                        },
                                        hover: {
                                            fill: "#c48f18",
                                            stroke: "#9e6d00",
                                            strokeWidth: 0.75,
                                            outline: "none",
                                            transition: "all 250ms"
                                        },
                                        pressed: {
                                            fill: "#DD4132",
                                            stroke: "#9e6d00",
                                            strokeWidth: 0.75,
                                            outline: "none",
                                            transition: "all 250ms"
                                        }
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            )}
        </ComposableMap>
    );
};

export default MapChart;
