export default

{
    "ALL": {
        "thirteenpluscount": 166,
        "totalresponses": 3736,
        "zerothreeaverage": 40800.726,
        "eighttwelveaverage": 64167.02,
        "zerothreecount": 1348,
        "foursevenaverage": 51237.854,
        "salaryaverage": 52726.555,
        "countryname": "All Countries",
        "foursevencount": 1387,
        "thirteenplusaverage": 90831.886,
        "eighttwelvecount": 711,
        "countrycode": "ALL"
    },
    "AFG": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 79080.0,
        "salaryaverage": 79080.0,
        "countryname": "Afghanistan",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "AFG"
    },
    "ALB": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 5292.0,
        "eighttwelveaverage": 9422.0,
        "zerothreecount": 1,
        "foursevenaverage": 10000.0,
        "salaryaverage": 8238.0,
        "countryname": "Albania",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "ALB"
    },
    "ARG": {
        "thirteenpluscount": 1,
        "totalresponses": 41,
        "zerothreeaverage": 13073.118,
        "eighttwelveaverage": 24380.5,
        "zerothreecount": 17,
        "foursevenaverage": 16701.214,
        "salaryaverage": 16656.78,
        "countryname": "Argentina",
        "foursevencount": 14,
        "thirteenplusaverage": 42000.0,
        "eighttwelvecount": 6,
        "countrycode": "ARG"
    },
    "ARM": {
        "thirteenpluscount": 0,
        "totalresponses": 5,
        "zerothreeaverage": 20133.333,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 3,
        "foursevenaverage": 23256.0,
        "salaryaverage": 21382.4,
        "countryname": "Armenia",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "ARM"
    },
    "AUS": {
        "thirteenpluscount": 5,
        "totalresponses": 69,
        "zerothreeaverage": 56029.52,
        "eighttwelveaverage": 87941.071,
        "zerothreecount": 25,
        "foursevenaverage": 81905.087,
        "salaryaverage": 76194.072,
        "countryname": "Australia",
        "foursevencount": 23,
        "thirteenplusaverage": 101361.8,
        "eighttwelvecount": 14,
        "countrycode": "AUS"
    },
    "AUT": {
        "thirteenpluscount": 0,
        "totalresponses": 21,
        "zerothreeaverage": 46370.222,
        "eighttwelveaverage": 58646.0,
        "zerothreecount": 9,
        "foursevenaverage": 61657.0,
        "salaryaverage": 54818.762,
        "countryname": "Austria",
        "foursevencount": 10,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "AUT"
    },
    "BGD": {
        "thirteenpluscount": 0,
        "totalresponses": 12,
        "zerothreeaverage": 6825.111,
        "eighttwelveaverage": 16800.0,
        "zerothreecount": 9,
        "foursevenaverage": 12254.0,
        "salaryaverage": 8561.167,
        "countryname": "Bangladesh",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "BGD"
    },
    "BLR": {
        "thirteenpluscount": 0,
        "totalresponses": 15,
        "zerothreeaverage": 9600.0,
        "eighttwelveaverage": 38800.0,
        "zerothreecount": 1,
        "foursevenaverage": 18300.0,
        "salaryaverage": 26033.333,
        "countryname": "Belarus",
        "foursevencount": 8,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 5,
        "countrycode": "BLR"
    },
    "BEL": {
        "thirteenpluscount": 1,
        "totalresponses": 26,
        "zerothreeaverage": 40085.692,
        "eighttwelveaverage": 57675.5,
        "zerothreecount": 13,
        "foursevenaverage": 59019.2,
        "salaryaverage": 49579.962,
        "countryname": "Belgium",
        "foursevencount": 10,
        "thirteenplusaverage": 62422.0,
        "eighttwelvecount": 2,
        "countrycode": "BEL"
    },
    "BLZ": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 22000.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 45567.0,
        "salaryaverage": 33783.5,
        "countryname": "Belize",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "BLZ"
    },
    "BOL": {
        "thirteenpluscount": 0,
        "totalresponses": 5,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 11840.6,
        "salaryaverage": 11840.6,
        "countryname": "Bolivia",
        "foursevencount": 5,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "BOL"
    },
    "BRA": {
        "thirteenpluscount": 5,
        "totalresponses": 135,
        "zerothreeaverage": 12185.075,
        "eighttwelveaverage": 23533.931,
        "zerothreecount": 40,
        "foursevenaverage": 19629.412,
        "salaryaverage": 19831.141,
        "countryname": "Brazil",
        "foursevencount": 51,
        "thirteenplusaverage": 31416.6,
        "eighttwelvecount": 29,
        "countrycode": "BRA"
    },
    "BGR": {
        "thirteenpluscount": 0,
        "totalresponses": 23,
        "zerothreeaverage": 40368.667,
        "eighttwelveaverage": 39768.0,
        "zerothreecount": 3,
        "foursevenaverage": 24419.385,
        "salaryaverage": 32576.87,
        "countryname": "Bulgaria",
        "foursevencount": 13,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 5,
        "countrycode": "BGR"
    },
    "CAN": {
        "thirteenpluscount": 12,
        "totalresponses": 119,
        "zerothreeaverage": 46482.054,
        "eighttwelveaverage": 73563.222,
        "zerothreecount": 37,
        "foursevenaverage": 58969.333,
        "salaryaverage": 60758.437,
        "countryname": "Canada",
        "foursevencount": 39,
        "thirteenplusaverage": 76406.667,
        "eighttwelvecount": 27,
        "countrycode": "CAN"
    },
    "CHL": {
        "thirteenpluscount": 1,
        "totalresponses": 23,
        "zerothreeaverage": 21892.0,
        "eighttwelveaverage": 29090.667,
        "zerothreecount": 7,
        "foursevenaverage": 25844.857,
        "salaryaverage": 30410.435,
        "countryname": "Chile",
        "foursevencount": 7,
        "thirteenplusaverage": 65520.0,
        "eighttwelvecount": 6,
        "countrycode": "CHL"
    },
    "CHN": {
        "thirteenpluscount": 0,
        "totalresponses": 18,
        "zerothreeaverage": 27604.444,
        "eighttwelveaverage": 94723.667,
        "zerothreecount": 9,
        "foursevenaverage": 53042.333,
        "salaryaverage": 47270.278,
        "countryname": "China",
        "foursevencount": 6,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 3,
        "countrycode": "CHN"
    },
    "COL": {
        "thirteenpluscount": 0,
        "totalresponses": 42,
        "zerothreeaverage": 12822.778,
        "eighttwelveaverage": 30392.111,
        "zerothreecount": 18,
        "foursevenaverage": 19742.833,
        "salaryaverage": 21033.167,
        "countryname": "Colombia",
        "foursevencount": 12,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 9,
        "countrycode": "COL"
    },
    "CRI": {
        "thirteenpluscount": 0,
        "totalresponses": 10,
        "zerothreeaverage": 19967.0,
        "eighttwelveaverage": 45398.5,
        "zerothreecount": 7,
        "foursevenaverage": 24000.0,
        "salaryaverage": 25456.6,
        "countryname": "Costa Rica",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "CRI"
    },
    "CIV": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 68311.0,
        "salaryaverage": 68311.0,
        "countryname": "Côte d’Ivoire",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "CIV"
    },
    "HRV": {
        "thirteenpluscount": 0,
        "totalresponses": 16,
        "zerothreeaverage": 11745.0,
        "eighttwelveaverage": 33000.0,
        "zerothreecount": 3,
        "foursevenaverage": 23120.6,
        "salaryaverage": 23042.875,
        "countryname": "Croatia",
        "foursevencount": 10,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "HRV"
    },
    "CUB": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 466.0,
        "zerothreecount": 0,
        "foursevenaverage": 0.0,
        "salaryaverage": 466.0,
        "countryname": "Cuba",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "CUB"
    },
    "CYP": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 6500.0,
        "eighttwelveaverage": 30316.0,
        "zerothreecount": 1,
        "foursevenaverage": 58502.0,
        "salaryaverage": 31772.667,
        "countryname": "Cyprus",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "CYP"
    },
    "CZE": {
        "thirteenpluscount": 1,
        "totalresponses": 33,
        "zerothreeaverage": 24943.667,
        "eighttwelveaverage": 47100.167,
        "zerothreecount": 12,
        "foursevenaverage": 35556.0,
        "salaryaverage": 35245.788,
        "countryname": "Czech Republic",
        "foursevencount": 12,
        "thirteenplusaverage": 65995.0,
        "eighttwelvecount": 6,
        "countrycode": "CZE"
    },
    "DNK": {
        "thirteenpluscount": 1,
        "totalresponses": 30,
        "zerothreeaverage": 60585.474,
        "eighttwelveaverage": 101322.667,
        "zerothreecount": 19,
        "foursevenaverage": 82205.0,
        "salaryaverage": 71247.833,
        "countryname": "Denmark",
        "foursevencount": 6,
        "thirteenplusaverage": 53155.0,
        "eighttwelvecount": 3,
        "countrycode": "DNK"
    },
    "DOM": {
        "thirteenpluscount": 0,
        "totalresponses": 6,
        "zerothreeaverage": 9528.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 4,
        "foursevenaverage": 16581.0,
        "salaryaverage": 11879.0,
        "countryname": "Dominican Republic",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "DOM"
    },
    "ECU": {
        "thirteenpluscount": 0,
        "totalresponses": 7,
        "zerothreeaverage": 17226.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 5,
        "foursevenaverage": 17000.0,
        "salaryaverage": 17161.429,
        "countryname": "Ecuador",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "ECU"
    },
    "EGY": {
        "thirteenpluscount": 0,
        "totalresponses": 20,
        "zerothreeaverage": 3971.5,
        "eighttwelveaverage": 11649.0,
        "zerothreecount": 4,
        "foursevenaverage": 6224.583,
        "salaryaverage": 6858.85,
        "countryname": "Egypt",
        "foursevencount": 12,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 4,
        "countrycode": "EGY"
    },
    "SLV": {
        "thirteenpluscount": 0,
        "totalresponses": 4,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 15300.0,
        "salaryaverage": 15300.0,
        "countryname": "El Salvador",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "SLV"
    },
    "EST": {
        "thirteenpluscount": 0,
        "totalresponses": 13,
        "zerothreeaverage": 39865.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 2,
        "foursevenaverage": 36781.0,
        "salaryaverage": 36885.385,
        "countryname": "Estonia",
        "foursevencount": 9,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "EST"
    },
    "ETH": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 5235.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 5235.0,
        "countryname": "Ethiopia",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "ETH"
    },
    "FIN": {
        "thirteenpluscount": 3,
        "totalresponses": 29,
        "zerothreeaverage": 44805.714,
        "eighttwelveaverage": 74424.5,
        "zerothreecount": 7,
        "foursevenaverage": 53287.077,
        "salaryaverage": 59303.517,
        "countryname": "Finland",
        "foursevencount": 13,
        "thirteenplusaverage": 88961.0,
        "eighttwelvecount": 6,
        "countrycode": "FIN"
    },
    "FRA": {
        "thirteenpluscount": 2,
        "totalresponses": 79,
        "zerothreeaverage": 44618.025,
        "eighttwelveaverage": 70738.917,
        "zerothreecount": 40,
        "foursevenaverage": 57650.6,
        "salaryaverage": 52668.734,
        "countryname": "France",
        "foursevencount": 25,
        "thirteenplusaverage": 42988.5,
        "eighttwelvecount": 12,
        "countrycode": "FRA"
    },
    "DEU": {
        "thirteenpluscount": 9,
        "totalresponses": 252,
        "zerothreeaverage": 49846.902,
        "eighttwelveaverage": 74199.378,
        "zerothreecount": 112,
        "foursevenaverage": 60864.157,
        "salaryaverage": 60549.329,
        "countryname": "Germany",
        "foursevencount": 83,
        "thirteenplusaverage": 83798.333,
        "eighttwelvecount": 37,
        "countrycode": "DEU"
    },
    "GIB": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 51400.0,
        "zerothreecount": 0,
        "foursevenaverage": 64250.0,
        "salaryaverage": 57825.0,
        "countryname": "Gibraltar",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "GIB"
    },
    "GRC": {
        "thirteenpluscount": 1,
        "totalresponses": 21,
        "zerothreeaverage": 17440.5,
        "eighttwelveaverage": 18255.5,
        "zerothreecount": 10,
        "foursevenaverage": 24093.857,
        "salaryaverage": 20618.667,
        "countryname": "Greece",
        "foursevencount": 7,
        "thirteenplusaverage": 29350.0,
        "eighttwelvecount": 2,
        "countrycode": "GRC"
    },
    "GTM": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 22100.0,
        "salaryaverage": 22100.0,
        "countryname": "Guatemala",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "GTM"
    },
    "GGY": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 22502.0,
        "salaryaverage": 22502.0,
        "countryname": "Guernsey",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "GGY"
    },
    "HND": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 15000.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 15000.0,
        "countryname": "Honduras",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "HND"
    },
    "HKG": {
        "thirteenpluscount": 0,
        "totalresponses": 23,
        "zerothreeaverage": 35807.875,
        "eighttwelveaverage": 46283.5,
        "zerothreecount": 8,
        "foursevenaverage": 68656.154,
        "salaryaverage": 55285.217,
        "countryname": "Hong Kong",
        "foursevencount": 13,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "HKG"
    },
    "HUN": {
        "thirteenpluscount": 1,
        "totalresponses": 49,
        "zerothreeaverage": 24645.571,
        "eighttwelveaverage": 41934.929,
        "zerothreecount": 14,
        "foursevenaverage": 28271.167,
        "salaryaverage": 32080.286,
        "countryname": "Hungary",
        "foursevencount": 18,
        "thirteenplusaverage": 26325.0,
        "eighttwelvecount": 14,
        "countrycode": "HUN"
    },
    "IND": {
        "thirteenpluscount": 4,
        "totalresponses": 191,
        "zerothreeaverage": 15227.416,
        "eighttwelveaverage": 33892.037,
        "zerothreecount": 77,
        "foursevenaverage": 20951.042,
        "salaryaverage": 21539.319,
        "countryname": "India",
        "foursevencount": 72,
        "thirteenplusaverage": 34059.25,
        "eighttwelvecount": 27,
        "countrycode": "IND"
    },
    "IDN": {
        "thirteenpluscount": 0,
        "totalresponses": 28,
        "zerothreeaverage": 6981.273,
        "eighttwelveaverage": 23772.0,
        "zerothreecount": 11,
        "foursevenaverage": 11486.0,
        "salaryaverage": 10593.857,
        "countryname": "Indonesia",
        "foursevencount": 15,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "IDN"
    },
    "IRN": {
        "thirteenpluscount": 0,
        "totalresponses": 10,
        "zerothreeaverage": 5533.333,
        "eighttwelveaverage": 17773.333,
        "zerothreecount": 3,
        "foursevenaverage": 6046.667,
        "salaryaverage": 10556.0,
        "countryname": "Iran, Islamic Republic of",
        "foursevencount": 3,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 3,
        "countrycode": "IRN"
    },
    "IRL": {
        "thirteenpluscount": 1,
        "totalresponses": 36,
        "zerothreeaverage": 41869.333,
        "eighttwelveaverage": 73367.25,
        "zerothreecount": 9,
        "foursevenaverage": 73259.615,
        "salaryaverage": 74222.056,
        "countryname": "Ireland",
        "foursevencount": 13,
        "thirteenplusaverage": 116599.0,
        "eighttwelvecount": 8,
        "countrycode": "IRL"
    },
    "ISR": {
        "thirteenpluscount": 0,
        "totalresponses": 27,
        "zerothreeaverage": 44264.4,
        "eighttwelveaverage": 83919.111,
        "zerothreecount": 10,
        "foursevenaverage": 64178.625,
        "salaryaverage": 63383.148,
        "countryname": "Israel",
        "foursevencount": 8,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 9,
        "countrycode": "ISR"
    },
    "ITA": {
        "thirteenpluscount": 1,
        "totalresponses": 59,
        "zerothreeaverage": 31885.889,
        "eighttwelveaverage": 44155.143,
        "zerothreecount": 18,
        "foursevenaverage": 38238.24,
        "salaryaverage": 37977.847,
        "countryname": "Italy",
        "foursevencount": 25,
        "thirteenplusaverage": 42400.0,
        "eighttwelvecount": 14,
        "countrycode": "ITA"
    },
    "JPN": {
        "thirteenpluscount": 0,
        "totalresponses": 8,
        "zerothreeaverage": 47760.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 2,
        "foursevenaverage": 79207.833,
        "salaryaverage": 71345.875,
        "countryname": "Japan",
        "foursevencount": 6,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "JPN"
    },
    "JOR": {
        "thirteenpluscount": 0,
        "totalresponses": 4,
        "zerothreeaverage": 6805.5,
        "eighttwelveaverage": 84910.0,
        "zerothreecount": 2,
        "foursevenaverage": 30466.0,
        "salaryaverage": 32246.75,
        "countryname": "Jordan",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "JOR"
    },
    "KAZ": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 10580.5,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 2,
        "foursevenaverage": 0.0,
        "salaryaverage": 10580.5,
        "countryname": "Kazakhstan",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "KAZ"
    },
    "KEN": {
        "thirteenpluscount": 0,
        "totalresponses": 5,
        "zerothreeaverage": 9688.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 2,
        "foursevenaverage": 15301.333,
        "salaryaverage": 13056.0,
        "countryname": "Kenya",
        "foursevencount": 3,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "KEN"
    },
    "KOR": {
        "thirteenpluscount": 0,
        "totalresponses": 6,
        "zerothreeaverage": 28786.6,
        "eighttwelveaverage": 41860.0,
        "zerothreecount": 5,
        "foursevenaverage": 0.0,
        "salaryaverage": 30965.5,
        "countryname": "Korea, Republic of",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "KOR"
    },
    "KWT": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 47408.0,
        "salaryaverage": 47408.0,
        "countryname": "Kuwait",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "KWT"
    },
    "LVA": {
        "thirteenpluscount": 0,
        "totalresponses": 17,
        "zerothreeaverage": 18304.5,
        "eighttwelveaverage": 44227.5,
        "zerothreecount": 6,
        "foursevenaverage": 33250.143,
        "salaryaverage": 30558.118,
        "countryname": "Latvia",
        "foursevencount": 7,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 4,
        "countrycode": "LVA"
    },
    "LBN": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 40000.0,
        "zerothreecount": 0,
        "foursevenaverage": 35000.0,
        "salaryaverage": 36666.667,
        "countryname": "Lebanon",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "LBN"
    },
    "LTU": {
        "thirteenpluscount": 1,
        "totalresponses": 17,
        "zerothreeaverage": 18365.5,
        "eighttwelveaverage": 21772.0,
        "zerothreecount": 4,
        "foursevenaverage": 26411.75,
        "salaryaverage": 23397.353,
        "countryname": "Lithuania",
        "foursevencount": 8,
        "thirteenplusaverage": 25911.0,
        "eighttwelvecount": 4,
        "countrycode": "LTU"
    },
    "LUX": {
        "thirteenpluscount": 0,
        "totalresponses": 6,
        "zerothreeaverage": 66231.0,
        "eighttwelveaverage": 67532.0,
        "zerothreecount": 1,
        "foursevenaverage": 66140.0,
        "salaryaverage": 69065.833,
        "countryname": "Luxembourg",
        "foursevencount": 3,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "LUX"
    },
    "MKD": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 32219.0,
        "zerothreecount": 0,
        "foursevenaverage": 14992.5,
        "salaryaverage": 20734.667,
        "countryname": "Macedonia, the Former Yugoslav Republic of",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "MKD"
    },
    "MYS": {
        "thirteenpluscount": 0,
        "totalresponses": 28,
        "zerothreeaverage": 12159.8,
        "eighttwelveaverage": 23937.778,
        "zerothreecount": 10,
        "foursevenaverage": 22769.0,
        "salaryaverage": 19433.143,
        "countryname": "Malaysia",
        "foursevencount": 7,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 9,
        "countrycode": "MYS"
    },
    "MLT": {
        "thirteenpluscount": 0,
        "totalresponses": 5,
        "zerothreeaverage": 44084.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 45998.75,
        "salaryaverage": 45615.8,
        "countryname": "Malta",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MLT"
    },
    "MUS": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 19108.0,
        "eighttwelveaverage": 22870.0,
        "zerothreecount": 2,
        "foursevenaverage": 0.0,
        "salaryaverage": 20362.0,
        "countryname": "Mauritius",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "MUS"
    },
    "MYT": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 16790.0,
        "salaryaverage": 16790.0,
        "countryname": "Mayotte",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MYT"
    },
    "MEX": {
        "thirteenpluscount": 0,
        "totalresponses": 50,
        "zerothreeaverage": 17424.944,
        "eighttwelveaverage": 26716.375,
        "zerothreecount": 18,
        "foursevenaverage": 18526.136,
        "salaryaverage": 19411.84,
        "countryname": "Mexico",
        "foursevencount": 22,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 8,
        "countrycode": "MEX"
    },
    "MDA": {
        "thirteenpluscount": 0,
        "totalresponses": 5,
        "zerothreeaverage": 10814.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 14242.0,
        "salaryaverage": 13556.4,
        "countryname": "Moldova, Republic of",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MDA"
    },
    "MNG": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 6240.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 6240.0,
        "countryname": "Mongolia",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MNG"
    },
    "MNE": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 7066.5,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 2,
        "foursevenaverage": 0.0,
        "salaryaverage": 7066.5,
        "countryname": "Montenegro",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MNE"
    },
    "MAR": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 1006.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 2220.0,
        "salaryaverage": 1613.0,
        "countryname": "Morocco",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MAR"
    },
    "MMR": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 7200.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 7200.0,
        "countryname": "Myanmar",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "MMR"
    },
    "NPL": {
        "thirteenpluscount": 0,
        "totalresponses": 9,
        "zerothreeaverage": 13250.0,
        "eighttwelveaverage": 51156.25,
        "zerothreecount": 2,
        "foursevenaverage": 8456.0,
        "salaryaverage": 28499.222,
        "countryname": "Nepal",
        "foursevencount": 3,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 4,
        "countrycode": "NPL"
    },
    "NLD": {
        "thirteenpluscount": 4,
        "totalresponses": 88,
        "zerothreeaverage": 39202.784,
        "eighttwelveaverage": 68601.647,
        "zerothreecount": 37,
        "foursevenaverage": 55741.433,
        "salaryaverage": 52253.83,
        "countryname": "Netherlands",
        "foursevencount": 30,
        "thirteenplusaverage": 77340.75,
        "eighttwelvecount": 17,
        "countrycode": "NLD"
    },
    "NZL": {
        "thirteenpluscount": 1,
        "totalresponses": 16,
        "zerothreeaverage": 52596.75,
        "eighttwelveaverage": 80008.5,
        "zerothreecount": 4,
        "foursevenaverage": 66277.0,
        "salaryaverage": 67152.062,
        "countryname": "New Zealand",
        "foursevencount": 8,
        "thirteenplusaverage": 96408.0,
        "eighttwelvecount": 2,
        "countrycode": "NZL"
    },
    "NIC": {
        "thirteenpluscount": 0,
        "totalresponses": 3,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 2950.0,
        "zerothreecount": 0,
        "foursevenaverage": 14940.0,
        "salaryaverage": 10943.333,
        "countryname": "Nicaragua",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "NIC"
    },
    "NGA": {
        "thirteenpluscount": 0,
        "totalresponses": 11,
        "zerothreeaverage": 14303.857,
        "eighttwelveaverage": 8340.0,
        "zerothreecount": 7,
        "foursevenaverage": 8764.333,
        "salaryaverage": 12250.909,
        "countryname": "Nigeria",
        "foursevencount": 3,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "NGA"
    },
    "NOR": {
        "thirteenpluscount": 1,
        "totalresponses": 20,
        "zerothreeaverage": 58827.0,
        "eighttwelveaverage": 83656.8,
        "zerothreecount": 6,
        "foursevenaverage": 71925.571,
        "salaryaverage": 72887.45,
        "countryname": "Norway",
        "foursevencount": 7,
        "thirteenplusaverage": 100123.0,
        "eighttwelvecount": 5,
        "countrycode": "NOR"
    },
    "PAK": {
        "thirteenpluscount": 0,
        "totalresponses": 17,
        "zerothreeaverage": 6823.333,
        "eighttwelveaverage": 25468.0,
        "zerothreecount": 6,
        "foursevenaverage": 9122.667,
        "salaryaverage": 10234.118,
        "countryname": "Pakistan",
        "foursevencount": 9,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "PAK"
    },
    "PSE": {
        "thirteenpluscount": 0,
        "totalresponses": 6,
        "zerothreeaverage": 10000.0,
        "eighttwelveaverage": 15600.0,
        "zerothreecount": 1,
        "foursevenaverage": 23850.25,
        "salaryaverage": 20166.833,
        "countryname": "Palestine, State of",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "PSE"
    },
    "PAN": {
        "thirteenpluscount": 0,
        "totalresponses": 7,
        "zerothreeaverage": 23775.0,
        "eighttwelveaverage": 32400.0,
        "zerothreecount": 2,
        "foursevenaverage": 14500.0,
        "salaryaverage": 24821.429,
        "countryname": "Panama",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "PAN"
    },
    "PER": {
        "thirteenpluscount": 1,
        "totalresponses": 16,
        "zerothreeaverage": 20100.0,
        "eighttwelveaverage": 23522.0,
        "zerothreecount": 8,
        "foursevenaverage": 29564.0,
        "salaryaverage": 23992.688,
        "countryname": "Peru",
        "foursevencount": 6,
        "thirteenplusaverage": 22177.0,
        "eighttwelvecount": 1,
        "countrycode": "PER"
    },
    "PHL": {
        "thirteenpluscount": 0,
        "totalresponses": 22,
        "zerothreeaverage": 12084.455,
        "eighttwelveaverage": 19384.667,
        "zerothreecount": 11,
        "foursevenaverage": 10262.625,
        "salaryaverage": 12417.455,
        "countryname": "Philippines",
        "foursevencount": 8,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 3,
        "countrycode": "PHL"
    },
    "POL": {
        "thirteenpluscount": 1,
        "totalresponses": 198,
        "zerothreeaverage": 18429.156,
        "eighttwelveaverage": 36661.5,
        "zerothreecount": 77,
        "foursevenaverage": 29767.963,
        "salaryaverage": 26919.268,
        "countryname": "Poland",
        "foursevencount": 81,
        "thirteenplusaverage": 18429.0,
        "eighttwelvecount": 36,
        "countrycode": "POL"
    },
    "PRT": {
        "thirteenpluscount": 0,
        "totalresponses": 44,
        "zerothreeaverage": 22903.625,
        "eighttwelveaverage": 33458.6,
        "zerothreecount": 16,
        "foursevenaverage": 33686.412,
        "salaryaverage": 29587.023,
        "countryname": "Portugal",
        "foursevencount": 17,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 10,
        "countrycode": "PRT"
    },
    "QAT": {
        "thirteenpluscount": 0,
        "totalresponses": 10,
        "zerothreeaverage": 59327.0,
        "eighttwelveaverage": 76080.5,
        "zerothreecount": 1,
        "foursevenaverage": 71960.8,
        "salaryaverage": 74982.1,
        "countryname": "Qatar",
        "foursevencount": 5,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "QAT"
    },
    "ROU": {
        "thirteenpluscount": 2,
        "totalresponses": 53,
        "zerothreeaverage": 20225.429,
        "eighttwelveaverage": 34026.529,
        "zerothreecount": 14,
        "foursevenaverage": 26531.316,
        "salaryaverage": 28781.83,
        "countryname": "Romania",
        "foursevencount": 19,
        "thirteenplusaverage": 39867.5,
        "eighttwelvecount": 17,
        "countrycode": "ROU"
    },
    "RUS": {
        "thirteenpluscount": 0,
        "totalresponses": 45,
        "zerothreeaverage": 18621.778,
        "eighttwelveaverage": 23757.909,
        "zerothreecount": 9,
        "foursevenaverage": 24513.917,
        "salaryaverage": 23939.267,
        "countryname": "Russian Federation",
        "foursevencount": 24,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 11,
        "countrycode": "RUS"
    },
    "SAU": {
        "thirteenpluscount": 1,
        "totalresponses": 10,
        "zerothreeaverage": 36596.0,
        "eighttwelveaverage": 43835.0,
        "zerothreecount": 4,
        "foursevenaverage": 15997.5,
        "salaryaverage": 35787.7,
        "countryname": "Saudi Arabia",
        "foursevencount": 2,
        "thirteenplusaverage": 47993.0,
        "eighttwelvecount": 3,
        "countrycode": "SAU"
    },
    "SRB": {
        "thirteenpluscount": 0,
        "totalresponses": 20,
        "zerothreeaverage": 13609.6,
        "eighttwelveaverage": 29346.0,
        "zerothreecount": 5,
        "foursevenaverage": 21814.111,
        "salaryaverage": 22022.55,
        "countryname": "Serbia",
        "foursevencount": 9,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 6,
        "countrycode": "SRB"
    },
    "SGP": {
        "thirteenpluscount": 0,
        "totalresponses": 40,
        "zerothreeaverage": 47181.933,
        "eighttwelveaverage": 46035.0,
        "zerothreecount": 15,
        "foursevenaverage": 50164.143,
        "salaryaverage": 51684.175,
        "countryname": "Singapore",
        "foursevencount": 21,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "SGP"
    },
    "SVK": {
        "thirteenpluscount": 1,
        "totalresponses": 10,
        "zerothreeaverage": 21571.0,
        "eighttwelveaverage": 30622.0,
        "zerothreecount": 2,
        "foursevenaverage": 23769.333,
        "salaryaverage": 29552.6,
        "countryname": "Slovakia",
        "foursevencount": 6,
        "thirteenplusaverage": 79146.0,
        "eighttwelvecount": 1,
        "countrycode": "SVK"
    },
    "SVN": {
        "thirteenpluscount": 1,
        "totalresponses": 10,
        "zerothreeaverage": 19722.0,
        "eighttwelveaverage": 36905.0,
        "zerothreecount": 4,
        "foursevenaverage": 38301.667,
        "salaryaverage": 29233.6,
        "countryname": "Slovenia",
        "foursevencount": 3,
        "thirteenplusaverage": 24733.0,
        "eighttwelvecount": 2,
        "countrycode": "SVN"
    },
    "ZAF": {
        "thirteenpluscount": 0,
        "totalresponses": 19,
        "zerothreeaverage": 23406.167,
        "eighttwelveaverage": 44043.2,
        "zerothreecount": 6,
        "foursevenaverage": 48023.571,
        "salaryaverage": 39713.158,
        "countryname": "South Africa",
        "foursevencount": 7,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 5,
        "countrycode": "ZAF"
    },
    "ESP": {
        "thirteenpluscount": 4,
        "totalresponses": 100,
        "zerothreeaverage": 30167.821,
        "eighttwelveaverage": 44308.714,
        "zerothreecount": 28,
        "foursevenaverage": 42299.512,
        "salaryaverage": 39612.34,
        "countryname": "Spain",
        "foursevencount": 41,
        "thirteenplusaverage": 53294.0,
        "eighttwelvecount": 21,
        "countrycode": "ESP"
    },
    "LKA": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 12454.0,
        "zerothreecount": 0,
        "foursevenaverage": 24013.0,
        "salaryaverage": 18233.5,
        "countryname": "Sri Lanka",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 1,
        "countrycode": "LKA"
    },
    "SWZ": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 56759.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 56759.0,
        "countryname": "Swaziland",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "SWZ"
    },
    "SWE": {
        "thirteenpluscount": 5,
        "totalresponses": 58,
        "zerothreeaverage": 44425.792,
        "eighttwelveaverage": 59878.143,
        "zerothreecount": 24,
        "foursevenaverage": 59495.067,
        "salaryaverage": 54203.897,
        "countryname": "Sweden",
        "foursevencount": 15,
        "thirteenplusaverage": 69377.4,
        "eighttwelvecount": 14,
        "countrycode": "SWE"
    },
    "CHE": {
        "thirteenpluscount": 4,
        "totalresponses": 51,
        "zerothreeaverage": 82491.65,
        "eighttwelveaverage": 124514.0,
        "zerothreecount": 20,
        "foursevenaverage": 100957.588,
        "salaryaverage": 100890.0,
        "countryname": "Switzerland",
        "foursevencount": 17,
        "thirteenplusaverage": 116047.75,
        "eighttwelvecount": 7,
        "countrycode": "CHE"
    },
    "SYR": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 2700.0,
        "salaryaverage": 2700.0,
        "countryname": "Syrian Arab Republic",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "SYR"
    },
    "TWN": {
        "thirteenpluscount": 0,
        "totalresponses": 10,
        "zerothreeaverage": 26668.25,
        "eighttwelveaverage": 19166.5,
        "zerothreecount": 4,
        "foursevenaverage": 46911.5,
        "salaryaverage": 33265.2,
        "countryname": "Taiwan, Province of China",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "TWN"
    },
    "THA": {
        "thirteenpluscount": 1,
        "totalresponses": 19,
        "zerothreeaverage": 15434.909,
        "eighttwelveaverage": 32816.667,
        "zerothreecount": 11,
        "foursevenaverage": 31826.25,
        "salaryaverage": 23733.842,
        "countryname": "Thailand",
        "foursevencount": 4,
        "thirteenplusaverage": 55404.0,
        "eighttwelvecount": 3,
        "countrycode": "THA"
    },
    "TUN": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 5788.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 12302.0,
        "salaryaverage": 9045.0,
        "countryname": "Tunisia",
        "foursevencount": 1,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "TUN"
    },
    "TUR": {
        "thirteenpluscount": 3,
        "totalresponses": 26,
        "zerothreeaverage": 12497.0,
        "eighttwelveaverage": 21456.6,
        "zerothreecount": 7,
        "foursevenaverage": 21062.909,
        "salaryaverage": 19584.192,
        "countryname": "Turkey",
        "foursevencount": 11,
        "thirteenplusaverage": 27578.333,
        "eighttwelvecount": 5,
        "countrycode": "TUR"
    },
    "UKR": {
        "thirteenpluscount": 0,
        "totalresponses": 54,
        "zerothreeaverage": 21287.778,
        "eighttwelveaverage": 35184.25,
        "zerothreecount": 18,
        "foursevenaverage": 23589.474,
        "salaryaverage": 26598.667,
        "countryname": "Ukraine",
        "foursevencount": 19,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 16,
        "countrycode": "UKR"
    },
    "ARE": {
        "thirteenpluscount": 2,
        "totalresponses": 15,
        "zerothreeaverage": 44923.0,
        "eighttwelveaverage": 81920.0,
        "zerothreecount": 2,
        "foursevenaverage": 35956.0,
        "salaryaverage": 64143.467,
        "countryname": "United Arab Emirates",
        "foursevencount": 6,
        "thirteenplusaverage": 89301.0,
        "eighttwelvecount": 4,
        "countrycode": "ARE"
    },
    "GBR": {
        "thirteenpluscount": 17,
        "totalresponses": 212,
        "zerothreeaverage": 42744.844,
        "eighttwelveaverage": 79318.267,
        "zerothreecount": 64,
        "foursevenaverage": 59067.268,
        "salaryaverage": 62241.146,
        "countryname": "United Kingdom",
        "foursevencount": 82,
        "thirteenplusaverage": 83603.882,
        "eighttwelvecount": 45,
        "countrycode": "GBR"
    },
    "USA": {
        "thirteenpluscount": 66,
        "totalresponses": 790,
        "zerothreeaverage": 76996.106,
        "eighttwelveaverage": 112202.669,
        "zerothreecount": 283,
        "foursevenaverage": 98732.354,
        "salaryaverage": 97153.116,
        "countryname": "United States",
        "foursevencount": 260,
        "thirteenplusaverage": 123446.879,
        "eighttwelvecount": 157,
        "countrycode": "USA"
    },
    "URY": {
        "thirteenpluscount": 0,
        "totalresponses": 6,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 40446.0,
        "zerothreecount": 0,
        "foursevenaverage": 39390.5,
        "salaryaverage": 39742.333,
        "countryname": "Uruguay",
        "foursevencount": 4,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 2,
        "countrycode": "URY"
    },
    "UZB": {
        "thirteenpluscount": 0,
        "totalresponses": 2,
        "zerothreeaverage": 0.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 0,
        "foursevenaverage": 21320.0,
        "salaryaverage": 21320.0,
        "countryname": "Uzbekistan",
        "foursevencount": 2,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "UZB"
    },
    "VEN": {
        "thirteenpluscount": 0,
        "totalresponses": 1,
        "zerothreeaverage": 7200.0,
        "eighttwelveaverage": 0.0,
        "zerothreecount": 1,
        "foursevenaverage": 0.0,
        "salaryaverage": 7200.0,
        "countryname": "Venezuela, Bolivarian Republic of",
        "foursevencount": 0,
        "thirteenplusaverage": 0.0,
        "eighttwelvecount": 0,
        "countrycode": "VEN"
    },
    "VNM": {
        "thirteenpluscount": 1,
        "totalresponses": 28,
        "zerothreeaverage": 12927.6,
        "eighttwelveaverage": 10000.0,
        "zerothreecount": 15,
        "foursevenaverage": 14417.5,
        "salaryaverage": 13776.929,
        "countryname": "Viet Nam",
        "foursevencount": 8,
        "thirteenplusaverage": 36500.0,
        "eighttwelvecount": 4,
        "countrycode": "VNM"
    }
}