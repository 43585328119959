import React, { Component } from 'react';
import A2Styles from './A2.module.css';
import ProjectStyles from './Projects.module.css';
import Projects from './Projects';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import ImageZoom from 'react-medium-image-zoom';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export default class A2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
  }

  render() {


    
    return (

      <Projects
      img="/assets/images/a2/table.png"
      classNameImg={A2Styles.sally_img}
      title="CoSpace"
      size="30%"> 

      <div className={A2Styles.container}>
      <div id="overview" style={{marginTop: 50}}>

      <div style={{margin: '20px auto'}}>
        <h1>Design Prompt</h1>
              <p> Design, document and illustrate a prototype where an everyday domestic object is turned into something that can connect you to a family member when they are away from home.</p>
      </div>

      <div style={{margin: '20px auto'}}>
          <h1>Tools</h1>
        <p>Pencil + paper, Photoshop, Adobe After Effects</p>
      </div>

      <hr/>

      <h1>The Solution</h1>
            <p>For this project, I really wanted to tackle long distance relationships and close the distance between loved ones.</p>

            <div className={A2Styles.video_container}>
            <iframe src="https://www.youtube.com/embed/XWB5T9VCCuo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={A2Styles.video}></iframe>
            </div>


          <div>
              <h1 style={{marginTop: 50}}>Rationale</h1>

              <p>I decided to use a table because tables leak a lot of information about the people who use them. Little things like what food you ate, what you're working on, if you put a remote down or how long things stayed on the table reveal information about you. </p>

              <p> I also used the table to show presence. When two people are living far apart, their presence is greatly missed. Part of living together means sharing a space and CoSpace mimics that.</p>
              <p>Here is the rationale behind my design choices. If you'd like to download the pdf,       <a href="/A2.pdf" target="_blank">click here</a> </p>

              <div className={A2Styles.video_container}>
                <iframe src="/A2.pdf" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={A2Styles.video}></iframe>
              </div>

          </div>

          </div>

      <hr/>
        <div className={ProjectStyles.title_flex} id="persona">

          <h1 style={{ fontSize: 40, fontWeight: 200, color: '#838592'}}>The Process</h1>
          <h1>Personas</h1>
      
      <p>In choosing to focus on long distance relationships, I started this project by exploring the needs of people in such relationships and crafting personas</p>

      <ImageZoom
            image={{
              src: '/assets/images/a2/per1.png',
              className: 'img',
              style: { width: '50%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a2/per1.png',
            }}
          />
      <ImageZoom
            image={{
              src: '/assets/images/a2/per2.png',
              className: 'img',
              style: { width: '50%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a2/per2.png',
            }}
          />
          </div>


          <div className={ProjectStyles.title_flex} id="ideation">

            <h1>Ideation</h1>


            <p>Mat and Tammy are in a long distance relationship where they live multiple time zones apart. With this in mind, I came up with sketches that consider this issue and try to circumnavigate it. I also considered the routines for each person as they enter and live in the home.</p>


      <ImageZoom
            image={{
              src: '/assets/images/a2/sk1.png',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/sk1.png',
            }}
          />
      </div>


      <div className={ProjectStyles.title_flex} id="feedback">
          <h1>Design Critique: Iteration 1</h1>
      
      <p>I presented my ideas to my peers so game me suggestions about how to connect further. I mainly focused on my table idea because I felt it was the most interesting and helps Mat and Tammy with the presence issue. I was given the suggestion to add messages to the objects on the table instead of an app and to consider how my personas would connect if they are in different timezones and aren't at home at the same time. I was also asked to consider different routines. For example, if one person didn't use a coffee table.</p>
      
      </div>
      <div className={ProjectStyles.title_flex} id="ideation2">
      <h1>Ideation: Part 2</h1>

      
      <p>Once I had more feedback, it was time to create another 10 ideas. Sticking with the table idea, I tried to incorporate more feedback into my designs. I added a timeline so if they aren't home, they could look through the timeline. I also added the comments to the ring around the table. Lastly, in order to tackle the different routine problem, I decided to make CoSpace a series of different kinds of tables. I felt that keeping the table was important because people use tables a lot, but I decided to let them choose what kind of table they use: coffee, dining, computer, kitchen counter. This gives them a lot of freedom.</p>
      
      <ImageZoom
            image={{
              src: '/assets/images/a2/sk2.png',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a2/sk2.png',
            }}
          />
      </div>

      <hr/>

      <div className={ProjectStyles.title_flex} id="final">
      <h1>Final Design</h1>
            <div className={A2Styles.video_container}>
              <iframe src="https://www.youtube.com/embed/XWB5T9VCCuo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={A2Styles.video}></iframe>
            </div>
          </div>

      </div>
      

      <div className={ProjectStyles.title_flex} id="nxtstep">
      <h1>Reflection</h1>
      <p>This project was a lot of fun to do. I really had to consider what the different routines were in people's lives and how to use that to my advantage. A limitation for CoSpace is that the timeline takes up a lot fo space on the table. Would this mean the person wouldn't be able to put things on that side of the table? In future iterations, this is a problem I'd like to tackle. </p>
      </div>
        <Nav items={['overview', 'persona', 'ideation', 'feedback', 'ideation2', 'final', 'nxtstep'] }>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#persona">Personas</a></li>
          <li><a href="#ideation">Ideation</a></li>
          <li><a href="#feedback">Design Critique</a></li>
          <li><a href="#ideation2">Ideation: Part 2</a></li>
          <li><a href="#final">Final Design </a></li>
          <li><a href="#nxtstep">Reflection</a></li>
      </Nav>



    </Projects>





    );
  }
}

