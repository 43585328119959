import React, { Component } from 'react';
import classnames from 'classnames';
import ProjectsStyles from './Projects.module.css';
import U from '../utilities.module.css';


class Projects extends Component {
  render() {
    
    return (
      <div>
        <div className={
          classnames(
            ProjectsStyles.product_mock,
            U.flex_column
          )}>
          <img src={this.props.img} width={this.props.size} className={this.props.classNameImg} alt=""/>
          <h3 style={{margin: '80px auto 50px auto', fontSize: '2rem'}}>{this.props.title}</h3>
        </div>
        <div className={ProjectsStyles.projects}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Projects;
