import React, { Component } from 'react';
import A1ProjectStyles from './A1Project.module.css';
import ProjectStyles from './Projects.module.css';
import Projects from './Projects';
import UtilitiesStyles from '../utilities.module.css';
import Nav from '../Nav';
import ImageZoom from 'react-medium-image-zoom';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export default class A1Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
    this.frame = React.createRef()
  }

  set = () => {
    this.setState({show: true})
  }


  render() {
    let height;
    if (!this.frame.current) {
      this.set()
    } else {
      let width = this.frame.current.getBoundingClientRect().width;
      height = width / 1.6; 
    }


    
    return (

      <Projects
      img="/assets/images/sally.png"
      classNameImg={A1ProjectStyles.sally_img}
      title="Save the Sea Turtles"
      size="30%"> 

      <div className={A1ProjectStyles.container}>
      <div id="overview" style={{marginTop: 50}}>

      <div style={{margin: '20px auto'}}>
        <h1>Design Prompt</h1>
              <p>Design and implement a "scrollytelling" data/information story. Your story will make use of scrolling in at least three different ways to convey different aspects of the story. Avoid using time as primary organizing narrative of your story</p>
      </div>

      <div style={{margin: '20px auto'}}>
          <h1>Tools</h1>
        <p>Pencil + paper, photoshop, scrollmagic, gsap</p>
      </div>

      <hr/>

      <h1>The Solution</h1>
      <p>I decided to tell the story of Sally the sea turtle and her plight to survive the horrors of a polluted ocean and get across the message to "Save the Ocean & Sea Turtles". Interact with the site below or click the button below to see it in another window. </p>
        <div className={UtilitiesStyles.text_center} style={{marginBottom: 60}}>

          <a
            href="/projects/a1"
            target="_blank"
            rel="noopener noreferrer"
            className={UtilitiesStyles.button}
          >
            See site in another window
          </a>
        </div>


          <iframe ref={this.frame} name="Framename" src="/projects/a1zoom" height={height} className={A1ProjectStyles.frame} frameBorder="0" scrolling="auto">
          </iframe>

          <div>
            <p>I used scrolling to convey this message in the following way.</p>
            <li>Let the user control the pace of Sally's swimming except when she came near garbage. At which point, she slows down and stops briefly before going through. I wanted to convey Sally's hesitation and discomfort as she traversed the pollution.   </li>
            <li>Facts and figures about garbage fly in from the left and right that Sally swerves to dodge. She also tries to swim through a spillage of sewage that only gets bigger as she tries to swim through it. I wanted to show the severity through the facts and the difficulty of living through Sally's dodging of garbage.</li>
            <li>As the user scrolls, Sally swims ashore and the baby sea turtles start swimming in the opposite direction towards the ocean. This is an inevitable process that shows that as Sally gets closer to her death, the baby sea turtles start theirs.</li>

          </div>

          </div>

      <hr/>
        <div className={ProjectStyles.title_flex} id="ideation">

          <h1 style={{ fontSize: 40, fontWeight: 200, color: '#838592'}}>The Process</h1>
          <h1>Ideation</h1>
      
      <p>For this prompts, I wanted to use concepts that I felt were either very important to society or very important to me so I did one for each category. I am passionate about space and I believe that we need to save the ocean so I came up with a few sketches for both.</p>

      <ImageZoom
            image={{
              src: '/assets/images/a11.jpg',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a11.jpg',
            }}
          />

          </div>



      <div className={ProjectStyles.title_flex} id="feedback">
          <h1>Design Critique</h1>
      
      <p>I ended up presenting only my ocean idea because I had more ideas that I wanted to get feedback on. I wasn't sure how to show that Sally had died and wasn't just lounging on the beach. I was given the idea to add a gravestone.</p>
      
      </div>
      <div className={ProjectStyles.title_flex} id="ideation2">
      <h1>Ideation: Part 2</h1>

      
      <p>Once I had more feedback, it was time to create another 10 ideas. This time, I added a few more ideas about the disasters Sally could go through. I received feedback about being more intentional about how I used scrolling to convey the message.</p>
      
      <ImageZoom
            image={{
              src: '/assets/images/a12.jpg',
              className: 'img',
              style: { width: '100%', marginTop: 50 }
            }}
            zoomImage={{
              src: '/assets/images/a12.jpg',
            }}
          />
      </div>

      <hr/>

      <div className={ProjectStyles.title_flex} id="final">
      <h1>Final Design</h1>

      
            <ImageZoom
              image={{
                src: '/assets/images/a1/hifi.png',
                className: 'img',
                style: { width: '180%', marginTop: 50 , marginLeft: "-40%"}
              }}
              zoomImage={{
                src: '/assets/images/a1/hifi.png',
              }}
            />
          </div>

      </div>

      <div className={ProjectStyles.title_flex} id="nxtstep">
      <h1>Reflection</h1>
      <p>I had a lot of fun creating this site. I did have a hard time making things exactly how I envisioned them in my head. I think if I had more time, I would like to take more time with the animations and introduce newer ideas. The site also has technical limitations in that it looks the best at a certain resolution. At smaller resolutions, the animations and placements of images take away from the storyline. I'd like to fix that for the next iteration. I would also like to learn more about scrollmagic and gsap so create better animation.</p>
      </div>
      <div className={ProjectStyles.title_flex} id="citation">
      <h1>Images Used</h1>
                <div className={A1ProjectStyles.cite}>
                  
                  
                <a href="https://mocah.org/5290800-hand-arm-sea-ocean-water-underwater-sunlight-summer-bubble-gopro-wafe-wave-blue-under-png-images.html">Top of the ocean</a>
                <a href="https://www.pngguru.com/free-transparent-background-png-clipart-aireu">Coral</a>
                  
                <a href="https://www.petanos.com/Pictures/siamese-fighting-fish-blue-transparent-background/">fish 1</a>
                <a href="https://www.netclipart.com/isee/oboRRo_tropical-fish-clipart-hawaiian-fish-transparent-transparent-background/">fish 2</a>
                <a href="https://www.netclipart.com/isee/oboRRo_tropical-fish-clipart-hawaiian-fish-transparent-transparent-background/">fish 3</a>
                  
                <a href="https://image.shutterstock.com/image-photo/tropical-fish-isolated-on-white-260nw-237118969.jpg">fish 4</a>
                <a href="https://myfox8.com/news/more-than-170-sea-turtles-found-dead-off-cape-cod/">Sally the sea turtle</a>
                <a href="https://image.shutterstock.com/image-photo/closeup-lit-cigarette-ash-isolated-260nw-46052758.jpg">Cigarette</a>
                <a href="https://singularityhub.com/2017/12/14/the-massive-project-to-clean-up-the-ocean-with-a-two-kilometer-screen/">Ocean Garbage</a>
                <a href="https://pngtree.com/freepng/coffee-paper-cup-vector-empty-clean-paper-or-plastic-container-for-drink-isolated-illustration_5204751.html">Coffee cup</a>
                <a href="https://favpng.com/png_view/recycled-plastic-bottles-plastic-bottle-recycling-waste-png/EC4Zc4mq">bottle</a>
                  <a href="https://www.seekpng.com/ipng/u2q8u2w7o0e6a9u2_photo-by-six-pack-plastic-ring-vector/">Soda ring</a>
                  <a href="https://blogs.umass.edu/natsci397a-eross/we-need-a-nationwide-plastic-ban/">Plastic bag</a>
                  <a href="https://flukerfarms.com/hermit-crab-care-sheet/">Hermit Crab</a>
                  <a href="https://www.shutterstock.com/video/clip-22084234-orange-plastic-storm-drain-pipe-water-runoff">Drain Pipe</a>
                  <a href="https://www.gettyimages.ca/detail/news-photo/floating-plastic-and-styrofoam-trash-polluting-a-corner-of-news-photo/672302992">Sewage</a>
                  <a href="http://www.vfthomas.com/Mainecemeteries/AndroscogginCounty/Durhamcemeteries/CedarGroveCemetery.htm">Grave Stone</a>
                  <a href="https://www.shutterstock.com/image-photo/turtle-baby-on-beach-top-view-1043161357">Baby Sea Turtle</a>
                  </div>
                  
      </div>
        <Nav items={['overview', 'ideation', 'feedback', 'ideation2', 'final', 'nxtstep','citation'] }>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#ideation">Ideation</a></li>
          <li><a href="#feedback">Design Critique</a></li>
          <li><a href="#ideation2">Ideation: Part 2</a></li>
          <li><a href="#Final">Final Design</a></li>
          <li><a href="#nxtstep">Reflection</a></li>
          <li><a href="#citation">Citations</a></li>
      </Nav>



    </Projects>





    );
  }
}

