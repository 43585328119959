import React, { Component, useState } from 'react';
import classnames from 'classnames';
import IndustryStyles from './Industry.module.css';
import UtilitiesStyles from '../utilities.module.css';
import ReactTooltip from "react-tooltip";
import MapChart from "./MapChart";
import country from './country.js'
import currencyFormatter from 'currency-formatter';
import NavSpecial from '../NavSpecial';



export default class Industry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: "",
    };


  }

  setContent = (props) => {
    if (country[props.ISO_A3]) {
      this.setState({
        content: (
          <div className={IndustryStyles.tooltip}>
            <h1 className={IndustryStyles.tip}>{country[props.ISO_A3].countryname} </h1>
            <h6>Junior UX Designer:</h6> <span>{currencyFormatter.format(country[props.ISO_A3].zerothreeaverage,{code: 'USD'})}</span> <br/>
            <h6>Mid Level UX Designer:</h6> <span>{currencyFormatter.format(country[props.ISO_A3].foursevenaverage, { code: 'USD' })}</span><br />
            <h6>Senior UX Designer:</h6> <span>{currencyFormatter.format(country[props.ISO_A3].eighttwelveaverage, { code: 'USD' })}</span><br />
          <h6> Lead UX Designer:</h6> <span>{currencyFormatter.format(country[props.ISO_A3].thirteenplusaverage,{code: 'USD'})}</span>
            </div>)
      })
    } else {
      this.setState({
        content: ""
      })
    }
  }


  render() {
    return (
      <div className={IndustryStyles.main}>
        <div className={IndustryStyles.hero}>
          <img src="https://cdn.dribbble.com/users/499731/screenshots/6648311/viceland_assets.png"/>
          <h1>Industry Profile: UX Design</h1>
        </div>
        <section className={classnames(IndustryStyles.intro)} id="intro">
          <h2>Introduction</h2>
          <p>The field of User Experience Design (UXD) has been gaining traction, and it is no surprise that many people end up wanting to enter this fascinating field. There are many ways to learn about UXD, including UXD as a career, UXD in the Day to Day, and UXD in the community. This article outlines UX into three sections: UX as a career, UX in the Day to Day and UX in the Community. Additionally, this article will feature tidbits of information from <a href="http://www.jasminfaith.com/" target="_blank">Jazz Ernest</a>, a UX designer at Pinterest.</p>

        </section>
        <section className={classnames(IndustryStyles.section, IndustryStyles.asAcar)} id="aac">
          <div>
            <img src="https://cdn.dribbble.com/users/3809802/screenshots/6998049/03_4x.jpg?compress=1&resize=1200x900" width="70%"/>
          </div>

          <div style={{ textAlign: 'center' }}>
            <h1>UX as a Career</h1>
          </div>
        
        </section>
        <section className={classnames(IndustryStyles.sect)}  style={{marginTop: -250}}>
          <h2>Introduction</h2>
          <p>This section will cover the overarching themes for the logistics of a career in User Experience Design (UXD). This includes salary, job mobility and where to find a job.</p>

        </section>
        <section className={classnames(IndustryStyles.sect)} id="position">
          <div>
            <h2>Positions</h2>
          </div>
          <br />
   
          <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.role)}>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/90192107_256517188684691_7919386400619757568_n.png?_nc_cat=110&_nc_sid=b96e70&_nc_ohc=NcK0KsNuV2cAX_Tvibz&_nc_ht=scontent.fybz1-1.fna&oh=3fae8c366aa8ec1d5f3bd2dfedc460db&oe=5E96720E" width="50%"/>
            <h5>UX Researcher</h5>
              <p>As the name suggests, a UX researcher focuses on the user through methods such as surveys, interviews and card sorting (<a href="https://creative.artisantalent.com/types-of-ux-jobs/">Types</a>). They work to scope out pain points and issues that users face, even before a solution is created. </p>
          </div>
          
          
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/90015871_899135630519327_930816904505851904_n.png?_nc_cat=109&_nc_sid=b96e70&_nc_ohc=JlB_iWIZ2osAX8GE3r6&_nc_ht=scontent.fybz1-1.fna&oh=9b9b508d799fbe66c8ba0ef2fc3dc6a2&oe=5E975EF6" width="56%" />

              <h5>Interaction Design</h5>
              <p>Interaction designers focus on how a user might interact with a system (<a href="https://creative.artisantalent.com/types-of-ux-jobs/">Types</a>). They help create the user flows and feedback needed to ensure the user can complete their goal and receive timely and useful feedback.</p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/p1080x2048/90015879_1149265618798796_7422843217189535744_n.png?_nc_cat=103&_nc_sid=b96e70&_nc_ohc=0d8L4PAbQrsAX-c8OLn&_nc_ht=scontent.fybz1-1.fna&oh=baf9c7e843fb605e5ea920789b0a67e2&oe=5E96CD56" width="50%" />
              <h5>User Interface Developer</h5>
              <p>User interface developers combine design, technical, and production skills to turn design into code (<a href="https://creative.artisantalent.com/types-of-ux-jobs/">Types</a>). They work with different types of code like HTML, CSS, and Javascript. These folks have a lot on their plates, as they are in charge of not only creating code, but also maintaining them!</p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/MessyDoodle.png" width="72%" />
              <h5>Information Architect</h5>
              <p>Information architects organize data and information (<a href="https://creative.artisantalent.com/types-of-ux-jobs/">Types</a>). One of their tasks is converting technical requirements into planning documents. They also create sitemaps, wireframes, user flows, and customer journeys.  </p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/MeditatingDoodle.png" width="72%" />
              <h5>Project Manager</h5>
              <p>Project managers need to be organized! They make the design process easier by coordinating communications, resources, and others for each project (<a href="https://creative.artisantalent.com/types-of-ux-jobs/">Types</a>). A good project manager also has a good sense of everybody’s skills and responsibilities.</p>
          </div>
          </div>
        </section>
        <section className={classnames(IndustryStyles.sect)} id="mobility">
          <div className={classnames(IndustryStyles.p_section_sect)}>
            <h2>Job Mobility</h2>
          </div>
          <div className={IndustryStyles.mob}>

          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/SittingDoodle.png" width="50%" />
              <h5>UX Internship</h5>
              <p>The UX internship is meant for students to get a taste of design in the industry (<a href="https://www.uxbeginner.com/two-common-ux-career-paths/">uxbeginner</a>). Interns are provided with projects that are fully scoped out with needs and requirements. They rely heavily on senior mentorship to get work done.</p>
          </div>

          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/GroovySittingDoodle.png" width="70%" />
              <h5>Junior UX Designer</h5>
              <p>Junior UX Designers have 0 - 4 years of experience and still rely heavily on senior designers for guidance (<a href="https://www.uxbeginner.com/two-common-ux-career-paths/">uxbeginner</a>). There is a little more autonomy for junior designers than interns and they have more industry experience. </p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/MoshingDoodle.png" width="50%" />
              <h5>Mid-Level UX Designer</h5>
              <p>Mid-level UX designers have 4 - 6+ years of experience and are proficient in one or more branches of UX designers (<a href="https://www.uxbeginner.com/two-common-ux-career-paths/">uxbeginner</a>). While they still report to senior designers, they tend to take on significantly more design responsibility. They have proficient knowledge of tools and UI patterns.</p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/PlantDoodle.png" width="62%" />
              <h5>Senior UX Designer</h5>
              <p>Senior UX Designers have 8 - 10 + years of experience and tend to take a more managerial role at their company (<a href="https://www.uxbeginner.com/two-common-ux-career-paths/">uxbeginner</a>). Instead of doing very technical work, they tend to solve bigger picture issues. They mentor more junior designers and handle multiple tasks and projects at the same time.</p>
          </div>
          <div className={classnames(IndustryStyles.p_section_sect)}>
              <img src="/assets/images/SleekDoodle.png" width="62%" />
              <h5>Lead UX Designer</h5>
              <p>Lead UX designers tend to do less heads-down design work and focus more on leading people and the vision of the products they work on (<a href="https://www.uxbeginner.com/two-common-ux-career-paths/">uxbeginner</a>). Lead UX designers work on strategizing the next steps for the team, making design processes, and bridging the design department and other departments.</p>
          </div>
          </div>
          <div className={classnames(IndustryStyles.quote, IndustryStyles.quote_mob)}>
            <p>
              "Smaller agencies/start-ups will have you doing a broad range of tasks/wearing multiple hats, whereas, at a larger company with more people, you can really dive into the details of one specific area and specialise in it."
              </p>
            <a href="https://jasminfaith.com/"><div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.quote_title)}>
              <div>
                <img src="/assets/images/jazz.png" className={IndustryStyles.jazz} />
              </div>
              <div>
                <h1>Jazz Ernest</h1>
              </div>
            </div>
            </a>
          </div>

        </section>
        <section className={IndustryStyles.salary} id="salary">
          <div className={IndustryStyles.sect}>
            <h2>Salary</h2>
            <p>The salary of a user experience designer differs depending on the country as well as the years of experience. Hover over the countries below to see the average salary in USD based on years of experience. This data has been collected from <a href="https://uxdesignersalaries.com/" target="_blank">uxdesignersalaries.com</a>.
              
              <br/><i>Darker colours mean higher average salary.</i></p>
          </div>
          <div className={IndustryStyles.map}>
            <MapChart setTooltipContent={this.setContent} />
            <ReactTooltip
              backgroundColor="#ffffff"
              textColor="black"
              borderColor="#d3a102"
              border="true"
            
            >{this.state.content}</ReactTooltip>
          </div>

          
        </section>

        <section className={classnames(IndustryStyles.sect)} id="sectors">
            <h2>Sectors</h2>
          <p>The nature of UXD leads it to be a versatile career path. Any sector with products that require human interaction will find use for a user experience designer. This includes private companies, public companies, government organizations, non-profit organizations, and charities (<a href="https://www.prospects.ac.uk/job-profiles/ux-designer">UX</a>). User experience designers can also be found in agencies that work on a per-project basis for third party companies. Here are a few sectors as well as companies where user experience designers can be found:</p>

            <li className={IndustryStyles.sector}><b>Technology</b> 
            <p><b>Google:</b> Google is a tech giant with products aimed to help people with every day tasks. UX designers have to learn about their users and crete designs that are easy to use and intuitive. (ex: Google Maps, Gmail)</p></li>
            <li className={IndustryStyles.sector}><b>Education</b> 
            
            <p><b>Khan Academy:</b> Khan Academy is a website aimed at people who want to learn. This means that designers have to create an environment that is conducive to learning and understanding.</p></li>
            <li className={IndustryStyles.sector}><b>Banking</b>
            <p><b>TD Bank:</b> TD Bank has come to the forefront with more online and mobile experiences for customers. Designers in this field create and environment where people can understand financial terms. They also have a responsibility to understand security in design.</p>
            </li>
            <li className={IndustryStyles.sector}><b>Retail</b>
            <p><b>Honey:</b> Honey is a web extension that looks for coupon codes for online shopping. Designers in this area have to understand the retail experience and design for an online retail environment to help drive more purchases.</p>
            </li>
            <li className={IndustryStyles.sector}><b>Health</b>
            <p><b>Maple:</b> Maple is an site to talk to doctors online. Designers of health care tools have to consider ethics and privacy when designing the interface.  </p></li>
        </section>
        <section className={classnames(IndustryStyles.sect)} id="org">

            <h2>Organizational Structures & Cultures</h2>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.cultures)}>
              <div className={IndustryStyles.culture}>
                <div>
                <img src="https://media.nngroup.com/media/editor/2019/09/04/centralized_ux_team.png" width="300px"/>
                </div>
                <div>
                <h5>Centralized UX Team</h5>
                <p>UX designers who choose to work in a centralized team report to a UX manager (<a href="https://www.nngroup.com/articles/ux-team-models/">Kaplan and Pernice</a>). They also often work as consultants for numerous products and processes in the business.</p>
              </div>
              </div>
              <div className={IndustryStyles.culture}>
                <div>
                <img src="https://media.nngroup.com/media/editor/2019/09/04/decentralized_ux_team.png" width="300px"/>
              </div>
              <div>
                <h5>Decentralized UX Team</h5>
                <p>In a decentralized UX Team model, UX designers are split into different teams and are dedicated to those teams and their products (<a href="https://www.nngroup.com/articles/ux-team-models/">Kaplan and Pernice</a>).</p>
              </div>
              </div>
              <div className={IndustryStyles.culture}>
                <div>
                <img src="https://media.nngroup.com/media/editor/2019/09/04/matrix_ux_team.png" width="300px" />
                </div>
                <div>
                <h5>Matrix UX Team</h5>
                <p>Matrix UX Teams combine both centralized and decentralized models (<a href="https://www.nngroup.com/articles/ux-team-models/">Kaplan and Pernice</a>). UX designers in this model are led by both a team lead and a UX lead.</p>
              </div>
              </div>

            <div className={classnames(IndustryStyles.quote, IndustryStyles.quote_org)}>
              <p>
                "I think trust is probably the #1 thing that is needed. If your team doesn't trust each other, you're not going to be able to build on top of people's thoughts and opinions because people won't express them"
              </p>
              <a href="https://jasminfaith.com/"> <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.quote_title)}>
                <div>
                  <img src="/assets/images/jazz.png" className={IndustryStyles.jazz} />
                </div>
                <div>
                  <h1>Jazz Ernest</h1>
                </div>
              </div>
              </a>
            </div>
            </div>
        </section>

        <section className={IndustryStyles.sect} id="where">
          <div className={IndustryStyles.p_section}>
            <h2>Where to Find a Job in UX</h2>
            <p>One of the best ways to get a job in UX is by building one's network and leveraging it to get referrals to companies. Beyond that, the best place to look for jobs as a user experience designer is through websites that advertise jobs.  such as:</p>
          
          <div className={IndustryStyles.link}>
          
            <a href="https://www.glassdoor.ca/Job/toronto-user-experience-designer-jobs-SRCH_IL.0,7_IC2281069_KO8,32.htm" target="_blank">GlassDoor</a>
            <a href="https://ca.indeed.com/jobs?q=user+experience+designer&l=Toronto%2C+ON" target="_blank">Indeed</a>
            <a href="https://www.linkedin.com/search/results/all/?keywords=user%20experience%20designer" target="_blank">LinkedIn</a>
            <a href="https://www.monster.ca/jobs/search/?q=user-experience-designer" target="_blank">Monster</a>
            <a href="https://interns.design/" target="_blank">Interns Jobs</a>
            
          </div>

          </div>


        </section>

        <section className={IndustryStyles.asAJob} id="asajob">

          <div>
            <img src="https://image.freepik.com/free-vector/marketing-strategy-financial-analytic-company_33099-1814.jpg"/>

          </div>

          <div style={{textAlign: 'center'}}>
                <h1>UX in the Day to Day</h1>
          </div>
        </section>
        <section className={classnames(IndustryStyles.sect)} style={{marginTop: -70}}>
          <h2>Introduction</h2>
          <p>User Experience Design (UXD) is a multi-faceted field! As such, being involved in this job means that it is likely for you to also dip your toes in a variety of areas in this field, including user interface design, user experience research, and security. This section delves into more of the day to day work and the core competencies required for the job.</p>
        </section>
        <section className={classnames(IndustryStyles.sect)}>
          <h2>What Do UX Designers Do?</h2>
          <img src="https://cindyology.com/media/UX-Design-Process.png" width='100%'/>
          <p>In a very general sense, UX designers follow a process. They go from the unknown to solid, actionable designs by iterating quickly and often. The image above shows the steps in the process and the different skills that may be used during that step. </p>
          <p>In the day to day, designers also have to interface with stake holders and are involved in many meeting where they discuss and critique designs and ideas. The next few sections will focus on a few different roles and their day to day work.</p>

          <div className={IndustryStyles.quote} style={{ marginRight: -70 , marginLeft:'auto', width: '100%'}}>
            <p>
              "Monday we have sprint planning, then the designers on the team get together to prioritise and assign work. Tuesday and Thursday is mostly no meetings except for offline crit and XFT, which is where we share what we're working on with cross-functional partners (eng, pm, and others). Wednesday is no meetings, and Friday mostly is heads-down time and in-person design-crit. I'd say most of my time is spent on interaction/visuals. My team is definitely light on exploring the actual problem space. We'd rather learn by doing and seeing what does/doesn't have an impact"
              </p>
            <a href="https://jasminfaith.com/"><div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.quote_title)}>
              <div>
                <img src="/assets/images/jazz.png" className={IndustryStyles.jazz} />
              </div>
              <div>
                <h1>Jazz Ernest</h1>
              </div>
            </div>
            </a>
          </div>
</section>
        <section className={classnames(IndustryStyles.sect)}>
          <div className={classnames(IndustryStyles.p_section_sect)}>
            
            <div id="uxr">
              <img src="https://cdn.dribbble.com/users/492311/screenshots/4641412/work__03-01.jpg" style={{width: '60%', margin: 'auto'}}/>
            <div style={{marginTop: 50}}>
              <h5>User Experience Researcher</h5>
            </div>
            <p>User Experience Researchers (UX Researchers) have a plethora of responsibilities on their plates! These responsibilities include, but are not limited to: research planning and recruitment, data collection and analysis, insight presentation, and strategy finding (<a href="https://careerfoundry.com/en/blog/ux-design/ux-researcher-career-guide/" target="_blank">Veal</a>). However, UX Researchers have a main goal, which is to research and share with their teams their target users’ needs, wants, and pain points. These findings will help the design team ensure that their design is user-friendly – and have peace of mind that all this information is coming from real users and not just self-referential assumptions!</p>

            <p> So, what does it take to be a competent UX Researcher? Being knowledgeable in human behavior from studies like cognitive science, anthropology, or psychology is definitely a plus in this field (<a href="https://careerfoundry.com/en/blog/ux-design/ux-researcher-career-guide/" target="_blank">Veal</a>). Moreover, empathy, experience in data analytics, and a firm grasp of the design process are also core UX Researcher skills. Although some people may elect to go to university or college like George Brown College to learn UX Research, there are also other pathways to learning more about this field, such as MooCs (Massive Open Online Courses), online videos, mentorship, books, and bootcamps (Wakefield).</p>
            </div>
            <div id="uid">
              <img src="https://cdn.dribbble.com/users/3212476/screenshots/7341949/media/3332eb77a5b4fccf7117a894b27bea27.jpg" style={{ width: '60%', margin: 'auto' }} />
            <div style={{marginTop: 50}}>
              <h5>User Interface Designer</h5>
            </div>
            <p>User Interface Designers (UI Designers) ensure that the design of whatever product they are designing – whether it be a phone application or a navigation interface – look good and are easy to use (<a href="https://careerfoundry.com/en/blog/ui-design/how-to-become-a-ui-designer/#2-what-exactly-does-a-ui-designer-do" target="_blank">Bieller</a>). As such, they must decide on the design’s colours, typography, and overall theme, making sure that these design elements work together in harmony and align with the user research.</p>

            <p> There are some core competencies that a good UI Designer must possess. Having the drive to keep learning, growing, and keeping up with the latest design trends is an essential trait for UI Designers (<a href="https://careerfoundry.com/en/blog/ui-design/how-to-become-a-ui-designer/#2-what-exactly-does-a-ui-designer-do" target="_blank">Bieller</a>). Furthermore, UI Designers don’t just sit in cubicles and work on their projects alone, barely talking to anybody! This job is collaborative, and it involves working with a team. That means UI Designers must be good with people; they must not only be comfortable working with a team, but also be able to take constructive criticism from the team and perhaps even clients. Aside from that, a UI Designer who pays attention to the users’ needs and wants, and applies this to their design, is a step above other designers. By combining these considerations and aesthetics, the UI Designer can create a design that is not only beautiful, but is also a joy to use.</p>
            </div>
            <div id="sec">
              <img src="https://cdn.dribbble.com/users/992274/screenshots/8185408/media/7dddca452f9023f328fd57b0bb3377d8.png" style={{ width: '60%', margin: 'auto' }} />
            <div style={{marginTop: 50}}>
              <h5>Security in UXD</h5>
            </div>
              <p>Security in a digital world is becoming more and more essential, so it is not surprising to see the importance of security becoming more recognized in UXD. UX Designers who want to build security in their designs must be able to create designs that are both easy to use and secure, without devaluing one or the other (<a href="https://www.toptal.com/designers/product-design/ux-security" target="_blank">Sharma</a>). They can do this by conducting User Experience Research to learn more about their users’ needs and pain points, and then figuring out when, where, and how much security to embed into the product (<a href="https://uxknowledgebase.com/designing-for-security-8822bdb4b10c?gi=3c90db67784f" target="_blank">Szerovay</a>).</p>

              <p>Research is essential in properly building security into the design. As such, it is no surprise that the core competencies in this field overlaps those of a general User Experience Researcher. Being knowledgeable in human behavior from studies like cognitive science, anthropology, or psychology is definitely a plus in this field (<a href="https://careerfoundry.com/en/blog/ux-design/ux-researcher-career-guide/" target="_blank">Veal</a>). Moreover, empathy, experience in data analytics, and a firm grasp of the design process are also core UX Researcher skills. Although some people may elect to go to university or college like George Brown College to learn UX Research, there are also other pathways to learning more about this field, such as MooCs (Massive Open Online Courses), online videos, mentorship, books, and bootcamps (<a href="https://uxplanet.org/ux-research-career-starter-guide-80dafda0a601" target="_blank">Wakefield</a>).</p>
            </div>
          </div>
        </section>
        <section className={classnames(IndustryStyles.sect)}>
          <div className={classnames(IndustryStyles.p_section)}>
            <h2>Perceptions of UXD</h2>
            <p>There are different perceptions of the UXD job. According to an interview with Pinterest’s Product Designer Jazz Ernest, because “most people in the tech bubble have never heard of a product designer,” she always ends up having to explain to them what she does. Even though UX Designers (and Product Designers) have a deeply involved role in the design process, Jazz usually ends up simplifying what she says, saying that she just designs phone apps.</p>
          </div>
          <div className={IndustryStyles.quote} style={{marginRight: -70}}>
            
            <p>
              "Most people not in the tech bubble have never heard of a product designer... basically just say I design the apps, even if it's not technically correct and more involved"
              </p>
            <a href="https://jasminfaith.com/"><div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.quote_title)}>
              <div>
                <img src="/assets/images/jazz.png" className={IndustryStyles.jazz} />
              </div>
              <div>
                <h1>Jazz Ernest</h1>
              </div>

            </div>
            </a>

          </div>
        </section>

        <section className={IndustryStyles.intcom} id="comm">

          <div>
            <img src="https://cdn.dribbble.com/users/499731/screenshots/7274007/media/8f6bea70612b3297793b4eaab8ae8f2b.png" width="70%"/>

          </div>

          <div style={{ textAlign: 'center' }}>
            <h1>UX in the Community</h1>
          </div>
        </section>

        <section className={classnames(IndustryStyles.cit)}>
          <h2>Introduction</h2>
          <p>The User Experience Design (UXD) community is lively and engaged since its members are always seeking new and exciting design strategies and continuously collaborate on design ideas.</p>

        </section>

        <section className={classnames(IndustryStyles.sect)} id="cit">
          <div className={classnames(IndustryStyles.p_section_sect)}>
            <h2>Current Issues & Trends</h2>

            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.role)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/90204079_560351778163215_4477541747253051392_n.png?_nc_cat=103&_nc_sid=b96e70&_nc_ohc=c6ASBsZqkhIAX_tOxAB&_nc_ht=scontent.fybz1-1.fna&oh=510bfa06fdcbc433c284ee42616a6228&oe=5E9BAB2A" width="50%" />
                <h5>Beauty over Function</h5>
                <p>One of the current issues in the UXD community is that young up-and-comer designers are creating work that is more superficial than truly useful in the real world (<a href="https://www.intercom.com/blog/the-dribbblisation-of-design/" target="_blank">Adams</a>). It seems that this trend is connected to sites like <a href="https://dribbble.com/" target="_blank">Dribbble</a>, where designs that look beautiful and exciting get a lot of positive attention. Employers are noticing this trend, and bemoan the fact that applicants are increasingly displaying work that looks beautiful but doesn’t reflect an ability to create practical, well-structured designs.</p>
              </div>

              <div className={classnames(IndustryStyles.p_section_sect)}>
                <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/p1080x2048/90435247_515684736004094_654895221724676096_n.png?_nc_cat=110&_nc_sid=b96e70&_nc_ohc=d0RTzUpve4QAX93lTik&_nc_ht=scontent.fybz1-1.fna&oh=d7ec545b893a33c585d5bdce0bc8cf1c&oe=5E9BA642" width="42%" />

                <h5>Illustrations</h5>
                <p>A popular visual trend in the UXD community is the use of illustrations (<a href="https://www.toptal.com/designers/ux/ux-design-trends-2019" target="_blank">Philips</a>). Although they were already popular in the past, the usage of illustrations has increased dramatically in the past year. Illustrations are being widely used on websites, and have also become popular for user wizards on mobile devices. Interestingly, illustrations often include a lot of tangible objects, such as papers and plants, and tend to have a modern vintage vibe. Stock images are now solidly the look of the past, and illustrations the look of the future.  </p>
              </div>


              
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/89983032_529521504610778_2327015862248144896_n.png?_nc_cat=104&_nc_sid=b96e70&_nc_ohc=lzC7BPCl4MAAX9R525Z&_nc_ht=scontent.fybz1-1.fna&oh=ba719bb24b9b8952028336c63f117c8c&oe=5E99BDBF" width="200px" style={{margin: 'auto'}} />

                <h5>Device Agnosticism</h5>
                <p>An important current trend in UXD is focusing on ensuring that all websites are “device agnostic,” meaning that websites have to be equally usable and user friendly on all devices, including phones and tablets (<a href="https://medium.com/swlh/the-ux-trends-for-2019-8485b6704ed7" target="_blank">Desai</a>). In the past, it may have been acceptable for companies to only focus on the usability of their website on computers, but nowadays, with a large number of people browsing the internet on their phones, it is critical for companies to ensure that their website is usable on all devices. This UXD trend is therefore timely and necessary for companies to stay relevant and profitable by providing a good user experience to everyone everywhere.</p>
              </div>

              <div className={classnames(IndustryStyles.p_section_sect)}>
                <img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/90584544_496592094367668_8806211110458884096_n.png?_nc_cat=111&_nc_sid=b96e70&_nc_ohc=WQ0yWxP8qnIAX-78yVV&_nc_ht=scontent.fybz1-1.fna&oh=8638037bf303b651c5f21d94616a6323&oe=5E9B111D" width="230px" style={{margin: 'auto'}} />

                <h5>Dark Design</h5>
                <p>Another issue in UXD is the use of dark design patterns, which are intentional design strategies meant to compel users to take an action that they don’t actually want to take. One dark design method is “bait and switch,” where the user interface is intentionally designed to get a user to click on something that they expect will lead to a certain outcome, but something undesirable happens instead (<a href="https://uxdesign.cc/dark-patterns-in-ux-design-7009a83b233c" target="_blank">Jaiswal</a>). “Disguised ads” is another dark design method, and designers are using this method when they create ads that match the design of the website they’re on so that users can’t tell the difference (<a href="https://uxdesign.cc/dark-patterns-in-ux-design-7009a83b233c" target="_blank">Jaiswal</a>). There are many more dark design patterns in use, and they range from being annoying to being quite devious. Although these design patterns are ethically questionable, many companies employ these techniques, and it may take legislation to stop, or at least curb, this behavior. </p>
              </div>
          </div>
          </div>

        </section>

        <section className={classnames(IndustryStyles.sect)} id="pdr">
          <div className={classnames(IndustryStyles.p_section_sect)}>
            <h2>Professional Development Resources</h2>
            <div>
            <h5>Professional Associations</h5>
            </div>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="http://hexagonux.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://scontent.fybz1-1.fna.fbcdn.net/v/t1.0-9/23130961_1669755969766391_7172243902365681679_n.png?_nc_cat=100&_nc_sid=85a577&_nc_ohc=aysz7xWS1iIAX_IU83w&_nc_ht=scontent.fybz1-1.fna&oh=68de8d846bc5142b9328ef75787b7deb&oe=5E99B7A0" width="120px"/></a>
                <p>Hexagon UX is an organization that supports women and non-binary people in UXD through their community.</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://uxpa.org/about-uxpa-international/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://media-exp1.licdn.com/dms/image/C560BAQGhXYKTz1EVHg/company-logo_200_200/0?e=1593043200&v=beta&t=XM9JezyYBTjjK6qe0TmRgZYDHdA2GEGvW_S6YuIkk9Q" width="120px" /> </a>
                <p>The User Experience Professionals Association (UXPA) promotes UXD and aims to support UX professionals through educational resources and career resources.</p>

              </div>
            </div>
            <div>
              <h5>Courses</h5>
            </div>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://www.lynda.com/learning-paths/Design/become-a-user-experience-designer" className={IndustryStyles.pdr_title} target="_blank"><img src="https://seeklogo.com/images/L/lynda_com-logo-9336EB3940-seeklogo.com.png" width="100px" style={{ marginBottom: 20 }}/></a>
                <p>Lynda.com has a wide variety of design courses, and includes a UXD dedicated Learning Path called “Become a User Experience Designer.”</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://www.mooc.org/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://www.mooc.org/hubfs/mooc-dot-org-logo.png" width="130px" style={{marginTop: 50, marginBottom: 50}}/></a>
                <p>MOOC.org offers many free courses, and includes several courses on UXD.</p>

              </div>
            </div>
            <div>
              <h5>Websites with Curated UXD Content</h5>
            </div>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://uxdesign.cc/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://miro.medium.com/fit/c/256/256/2*G8Li5YWeeyPoHvV8W_GqNA.jpeg" width="40px" style={{ marginBottom: 10 }}/> UX Collective</a>
                <p>Stories, blog posts and case studies about UX & Product Design.</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://www.uxmatters.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://rosenfeldmedia.com/eux2018/wp-content/uploads/sites/7/2018/05/UXmatters.png" width="130px"/></a>
                <p>An online magazine with articles on UXD. </p>

              </div>
            </div>
            <h5>Hackathons</h5>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://uofthacks.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://taamannae.dev/assets/images/uofthacks1.png" width="100px" style={{ marginBottom: 20 }}/></a>
                <p>A yearly hackathon at the University of Toronto.</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://uxhack.co/learning-hackathons/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://d4nkomjewj4zw.cloudfront.net/static/Hacks/images/UXHack-logo.png" width="200px" style={{marginTop: 50, marginBottom: 25}}/></a>
                <p>UXHack includes weekly “Learning Hackathons” where participants are challenged with designing solutions for real-world problems.</p>

              </div>
            </div>
            <h5>Conventions</h5>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://conference.awwwards.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://www.underconsideration.com/brandnew/archives/awwwards_logo.png" width="200px" style={{ marginBottom: 20 }}/></a>
                <p>An annual design conference held in several cities around the world.</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://designto.org/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://designto.org/wp-content/uploads/2019/08/designto-festival-logo-10years-black.png" width="130px" style={{marginBottom: 10}}/></a>
                <p>A yearly, week-long design conference held in Toronto.</p>

              </div>
            </div>
            <h5>Other Valuable Resources</h5>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.pdr)}>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://dribbble.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQcmgco_H0527f3bTBvudq29EGa6DJnpPIehq3jthvv-JIdSy7Z" width="200px" style={{ marginBottom: 20 }}/></a>
                <p>A place where people can showcase their design work and connect with other designers.</p>

              </div>
              <div className={classnames(IndustryStyles.p_section_sect)}>
                <a href="https://uibreakfast.com/" className={IndustryStyles.pdr_title} target="_blank"><img src="https://uibreakfast.com/wp-content/uploads/2019/11/uibreakfast_logo@2x.png" width="180px" style={{marginTop: 10, marginBottom: 30}}/></a>
                <p>A podcast about User Interface (UI) and UX design.</p>

              </div>
            </div>



          </div>
        </section>

        <section className={IndustryStyles.conclude} id="con">
          <div className={classnames(IndustryStyles.sect)}>
            <img src="https://cdn.dribbble.com/users/1020613/screenshots/4301150/community_for_dribbble.png" style={{ width: '60%', margin: 'auto' }} />
            <h2>Conclusion</h2>
            <p>UX is a new field with many different and interesting specializations. It requires a fair amount of problem solving, empathy, collaboration and analytical thinking. UX is definitely an interesting and constantly adapting field with a lot of room for growth and learning. With a rich community of designers, researchers and managers who work together to build products and solutions that aim to solve human problems, UX is a perfect field for those interested in design, human behaviour and technology. </p>

          </div>

        </section>
        <section className={IndustryStyles.conclude}>
          <div className={classnames(IndustryStyles.sect)}>
            <h2>Images Used</h2>
            <div className={classnames(UtilitiesStyles.d_flex, IndustryStyles.cite)}>
              <a href="https://dribbble.com/shots/6648311-Remote-Work-Designing-VICE/attachments">Remote Work: Designing VICE</a>
              <a href="https://dribbble.com/shots/7274007-AMA-What-s-unique-about-MetaLab-s-culture">AMA: What's unique about MetaLab's culture?</a>
              <a href="https://dribbble.com/shots/6998049-Scrum-III">Scrum III</a>
              <a href="https://dribbble.com/shots/8185408-Credit-cards-in-hands">Credit cards in hands</a>
              <a href="https://dribbble.com/shots/4641412-Researcher">Researcher!</a>
              <a href="https://dribbble.com/shots/7341949-Designer-Life">Designer Life</a>
              <a href="https://dribbble.com/shots/4301150-Healthy-Community">Healthy Community</a>

            </div>

          </div>

        </section>

        <NavSpecial items={['intro', 'aac', 'position', 'mobility', 'salary', 'sectors', 'org', 'where', 'asajob', 'uxr', 'uid', 'sec', 'comm', 'cit', 'pdr', 'con']}>
          <li><a href="#intro">Introduction</a></li>
          <li><a href="#aac">UX as a Career</a></li>
          <li className={IndustryStyles.tab}><a href="#position">Positions</a></li>
          <li className={IndustryStyles.tab}><a href="#mobility">Job Mobility</a></li>
          <li className={IndustryStyles.tab}><a href="#salary">Salary</a></li>
          <li className={IndustryStyles.tab}><a href="#sector">Sectors</a></li>
          <li className={IndustryStyles.tab}><a href="#org">Org Culture</a></li>
          <li className={IndustryStyles.tab}><a href="#where">Where to find job</a></li>
          <li><a href="#asajob">UX in the Day to Day</a></li>
          <li className={IndustryStyles.tab}><a href="#uxr">UX Researcher</a></li>
          <li className={IndustryStyles.tab}><a href="#uid">UI Designer</a></li>
          <li className={IndustryStyles.tab}><a href="#sec">Security in UXD</a></li>
          <li><a href="#comm">UX in the Community</a></li>
          <li className={IndustryStyles.tab}><a href="#cit">Current Issues & Trends</a></li>
          <li className={IndustryStyles.tab}><a href="#pdr">Development Resources</a></li>
          <li><a href="#con">Conclusion</a></li>

        </NavSpecial>


      </div>
    );
  }
}

